import type { FormSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { ZodSchema } from 'zod'

export const campaignFormResolver = (
  schema: ZodSchema<FormSchema>,
  opts: {
    productLimit: number
  }
) => {
  const { productLimit } = opts

  const { t } = useTranslation()

  return zodResolver(
    schema
      .refine(
        (data) => {
          // Name required
          return !!data.name
        },
        {
          // Custom error message
          message: t('validationNameRequired'),
          path: ['name'], // Specify that this error is for the name field
        }
      )
      .refine(
        (data) => {
          // startDate required
          return !!data.startDate
        },
        {
          // Custom error message
          message: t('validationStartDateRequired'),
          path: ['startDate'], // Specify that this error is for the startDate field
        }
      )
      .refine(
        (data) => {
          // If hasEndDate is true, endDate must not be null or undefined
          if (data.hasEndDate) {
            return data.endDate != null
          }
          // If hasEndDate is false, validation passes regardless of endDate
          return true
        },
        {
          // Custom error message
          message: t('validationEndDateRequired'),
          path: ['endDate'], // Specify that this error is for the endDate field
        }
      )
      .refine(
        (data) => {
          // If both startDate and endDate are defined, endDate must be after startDate
          if (data.startDate && data.endDate && data.hasEndDate) {
            return new Date(data.endDate) > new Date(data.startDate)
          }
          // If either startDate or endDate is not defined, validation passes
          return true
        },
        {
          // Custom error message
          message: t('validationEndDateAfterStartDate'),
          path: ['endDate'], // Specify that this error is for the endDate field
        }
      )
      .refine(
        (data) => {
          // Either productIds or variantIds needs to have at least one element
          return data.products && data.products.length > 0
        },
        {
          // Custom error message
          message: t('validationProductsRequired'),
          path: ['products'], // Specify that this error is for the productIds and variantIds fields
        }
      )
      .refine(
        (data) => {
          if (!data.products) {
            return false
          }

          const totalProductsAndVariants = data.products.reduce((acc, product) => {
            const isAllVariants = product.variants.length === 0

            acc += isAllVariants ? 1 : product.variants.length
            return acc
          }, 0)

          // Either productIds or variantIds needs to have at least one element
          return data.products && totalProductsAndVariants <= productLimit
        },
        {
          // Custom error message
          message: t('validationProductsMaxLimit', {
            count: productLimit,
          }),
          path: ['products'], // Specify that this error is for the productIds and variantIds fields
        }
      )
      .refine(
        (data) => {
          // Either sellingPlan.full or sellingPlan.partial needs to be active
          return data.sellingPlans.full.isActive || data.sellingPlans.partial.isActive
        },
        {
          // Custom error message
          message: t('validationPurchaseOptionActive'),
          path: ['sellingPlans.root'], // Specify that this error is for the sellingPlans field
        }
      )
      .refine(
        (data) => {
          // If sellingPlan.full is active, it needs a name
          if (data.sellingPlans.full.isActive) {
            if (!data.sellingPlans.full.name) {
              return false
            }
          }

          return true
        },
        {
          // Custom error message
          message: t('validationPurchaseOptionNameRequired'),
          path: ['sellingPlans', 'full', 'name'], // Specify that this error is for the sellingPlans field
        }
      )
      .refine(
        (data) => {
          // If not on the free plan and sellingPlan.partial is active, it needs a name
          if (data.sellingPlans.partial.isActive) {
            if (!data.sellingPlans.partial.name) {
              return false
            }
          }

          return true
        },
        {
          // Custom error message
          message: t('validationPurchaseOptionNameRequired'),
          path: ['sellingPlans', 'partial', 'name'], // Specify that this error is for the sellingPlans field
        }
      )
      .refine(
        (data) => {
          // If fulfillmentTrigger is 'EXACT_TIME', fulfillmentDate must be set
          if (data.fulfillmentTrigger === 'EXACT_TIME') {
            return data.fulfillmentDate != null
          }
          // If fulfillmentTrigger is not 'exact time', validation passes
          return true
        },
        {
          // Custom error message
          message: t('validationFulfillmentDateRequired'),
          path: ['fulfillmentDate'], // Specify that this error is for the fulfillmentDate field
        }
      )
      .refine(
        (data) => {
          // If fulfillmentTrigger is 'EXACT_TIME', fulfillmentDate is set, and endDate is set
          if (data.fulfillmentTrigger === 'EXACT_TIME' && data.fulfillmentDate && data.endDate) {
            // fulfillmentDate must be after endDate
            return new Date(data.fulfillmentDate) > new Date(data.endDate)
          }
          // If fulfillmentTrigger is not 'EXACT_TIME', fulfillmentDate is not set, or endDate is not set, validation passes
          return true
        },
        {
          // Custom error message
          message: t('validationFulfillmentDateAfterEndDate'),
          path: ['fulfillmentDate'], // Specify that this error is for the fulfillmentDate field
        }
      )
      .refine(
        (data) => {
          // If fulfillmentTrigger is 'EXACT_TIME', fulfillmentDate is set, and endDate is not set
          if (data.fulfillmentTrigger === 'EXACT_TIME' && data.fulfillmentDate && !data.endDate) {
            // fulfillmentDate must be after startDate
            return new Date(data.fulfillmentDate) > new Date(data.startDate)
          }
          // If fulfillmentTrigger is not 'EXACT_TIME', fulfillmentDate is not set, or endDate is set, validation passes
          return true
        },
        {
          // Custom error message
          message: t('validationFulfillmentDateAfterStartDate'),
          path: ['fulfillmentDate'], // Specify that this error is for the fulfillmentDate field
        }
      )
      .refine(
        (data) => {
          if (data.sellingPlans.partial.isActive) {
            // Partial amount between 0% and 100%
            if (data.sellingPlans.partial.partialType === 'PERCENTAGE') {
              return (
                typeof data.sellingPlans.partial.partialAmount === 'number' &&
                data.sellingPlans.partial.partialAmount >= 0 &&
                data.sellingPlans.partial.partialAmount < 100
              )
            }
          }
          return true
        },
        {
          message: t('validationPartialPlanPartialTypePercentageRange'),
          path: ['sellingPlans', 'partial', 'partialAmount'],
        }
      )
      .refine(
        (data) => {
          if (data.sellingPlans.partial.isActive) {
            // Partial amount between > 0 for fixed
            if (data.sellingPlans.partial.partialType === 'FIXED') {
              return typeof data.sellingPlans.partial.partialAmount === 'number' && data.sellingPlans.partial.partialAmount >= 0
            }
          }
          return true
        },
        {
          message: t('validationPartialPlanPartialTypeFixedRange'),
          path: ['sellingPlans', 'partial', 'partialAmount'],
        }
      )
      .refine(
        (data) => {
          if (data.sellingPlans.full.isActive && data.sellingPlans.full.hasDiscount) {
            // Partial amount between 0% and 100%
            if (data.sellingPlans.full.discountType === 'PERCENTAGE') {
              return data.sellingPlans.full?.discountAmount && data.sellingPlans.full?.discountAmount > 0 && data.sellingPlans.full?.discountAmount < 100
            }
          }
          return true
        },
        {
          message: t('validationSellingPlanDiscountPercentage'),
          path: ['sellingPlans', 'full', 'discountAmount'],
        }
      )
      .refine(
        (data) => {
          if (data.sellingPlans.full.isActive && data.sellingPlans.full.hasDiscount) {
            // Partial amount between 0% and 100%
            if (data.sellingPlans.full.discountType === 'FIXED') {
              return data.sellingPlans.full?.discountAmount && data.sellingPlans.full?.discountAmount > 0
            }
          }
          return true
        },
        {
          message: t('validationSellingPlanDiscountFixed'),
          path: ['sellingPlans', 'full', 'discountAmount'],
        }
      )
      .refine(
        (data) => {
          if (data.sellingPlans.partial.isActive && data.sellingPlans.partial.hasDiscount) {
            // Partial amount between 0% and 100%
            if (data.sellingPlans.partial.discountType === 'PERCENTAGE') {
              return (
                data.sellingPlans.partial?.discountAmount && data.sellingPlans.partial?.discountAmount > 0 && data.sellingPlans.partial?.discountAmount < 100
              )
            }
          }
          return true
        },
        {
          message: t('validationSellingPlanDiscountPercentage'),
          path: ['sellingPlans', 'partial', 'discountAmount'],
        }
      )
      .refine(
        (data) => {
          if (data.sellingPlans.partial.isActive && data.sellingPlans.partial.hasDiscount) {
            // Partial amount between 0% and 100%
            if (data.sellingPlans.partial.discountType === 'FIXED') {
              return data.sellingPlans.partial?.discountAmount && data.sellingPlans.partial?.discountAmount > 0
            }
          }
          return true
        },
        {
          message: t('validationSellingPlanDiscountFixed'),
          path: ['sellingPlans', 'partial', 'discountAmount'],
        }
      )
      .refine(
        (data) => {
          // If partial selling plan is active and finalPaymentTrigger is 'EXACT_TIME', paymentDueDate is required
          if (data.sellingPlans.partial.isActive) {
            if (data.sellingPlans.partial.finalPaymentTrigger === 'EXACT_TIME') {
              return data.sellingPlans.partial.paymentDueDate != null
            }
          }
          return true
        },
        {
          message: t('validationPartialPlanPaymentDueDateRequired'),
          path: ['sellingPlans', 'partial', 'paymentDueDate'],
        }
      )
      .refine(
        (data) => {
          // If partial selling plan is active and finalPaymentTrigger is 'TIME_AFTER_CHECKOUT', numberOfDays is required
          if (data.sellingPlans.partial.isActive) {
            if (data.sellingPlans.partial.finalPaymentTrigger === 'TIME_AFTER_CHECKOUT') {
              return typeof data.sellingPlans.partial.numberOfDays === 'number' && data.sellingPlans.partial.numberOfDays > 0
            }
          }
          return true
        },
        {
          message: t('validationPartialPlanNumberOfDaysRequired'),
          path: ['sellingPlans', 'partial', 'numberOfDays'],
        }
      )
      .refine(
        (data) => {
          // If hasStockLimit is true, stockLimit is required and must be greater than 0
          if (data.hasStockLimit && data.preorderTrigger === 'IN_STOCK') {
            return typeof data.stockLimit === 'number' && data.stockLimit > 0
          }
          return true
        },
        {
          message: t('validationStockLimitRange'),
          path: ['stockLimit'],
        }
      )
  )
}
