import { useState } from 'react'
import { ActionList, Button, ButtonGroup, Popover } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { Campaign } from '@/common/types'
import { ChevronDownIcon } from '@shopify/polaris-icons'
import dayjs from '@/common/datetime'
// Hooks
import { useMutateCampaignSchedule } from '@/hooks/useMutateCampaignSchedule'
import { useAppBridge } from '@shopify/app-bridge-react'

// Constants
const PROCESSING_STATUSES = ['PROCESSING_PRODUCTS', 'UPDATING_CAMPAIGN']

const usePublishingStatus = (campaign: Campaign) => {
  const isPublishing = !campaign.isPublished && PROCESSING_STATUSES.includes(campaign.scheduleStatus)
  const isUnpublishing = campaign.isPublished && PROCESSING_STATUSES.includes(campaign.scheduleStatus)
  return { isPublishing, isUnpublishing }
}

const CampaignPrimaryActions = ({ campaign, isDisabled, hasInvalidProducts }: { campaign: Campaign; isDisabled: boolean; hasInvalidProducts: boolean }) => {
  const [isPopoverActive, setIsPopoverActive] = useState(false)

  const { t } = useTranslation()
  const campaignScheduleMutation = useMutateCampaignSchedule(campaign.uuid)

  const isStartingImmediately = dayjs().toISOString() > campaign.startDate
  const endDatePassed = campaign.hasEndDate && campaign.endDate && dayjs().diff(dayjs(campaign.endDate), 'second') > 0

  const buttonProps = { campaign, hasInvalidProducts, campaignScheduleMutation }

  // Archived
  if (campaign.status === 'ARCHIVED') return null

  // Scheduled
  if (campaign.status === 'SCHEDULED') return <CancelButton {...buttonProps} />

  // Stopped and end date passed
  if (campaign.status === 'PAUSED' && endDatePassed) return <ArchiveButton {...buttonProps} />

  // Loading state
  if (campaign.scheduleStatus !== 'COMPLETE') return <LoadingButton />

  // Stopped (no end date, or it hasn't passed yet)
  if (campaign.status === 'PAUSED' && !endDatePassed) {
    return (
      <ButtonGroup variant="segmented">
        <PublishButton {...buttonProps} />
        <Popover
          active={isPopoverActive}
          preferredAlignment="right"
          activator={<Button variant="primary" onClick={() => setIsPopoverActive(true)} icon={ChevronDownIcon} />}
          autofocusTarget="first-node"
          onClose={() => setIsPopoverActive(false)}
        >
          <ActionList
            actionRole="menuitem"
            items={[
              {
                disabled: isDisabled,
                content: t('campaignsManageCtaArchive'),
                onAction: () => {
                  const archiveModal = document.getElementById('archive-campaign-modal') as UIModalElement
                  archiveModal.show()
                },
              },
            ]}
          />
        </Popover>
      </ButtonGroup>
    )
  }

  // Active
  if (campaign.status === 'ACTIVE') return <PauseButton {...buttonProps} />

  // Draft
  if (!campaign.isPublished) {
    return isStartingImmediately ? <PublishButton {...buttonProps} /> : <ScheduleButton {...buttonProps} />
  }

  return null
}

type ButtonProps = {
  campaign: Campaign
  hasInvalidProducts: boolean
  campaignScheduleMutation: ReturnType<typeof useMutateCampaignSchedule>
}

const LoadingButton = () => {
  const { t } = useTranslation()

  return (
    <Button variant="primary" disabled loading>
      {t('genericLoading')}
    </Button>
  )
}

const PublishButton = ({ campaign, hasInvalidProducts, campaignScheduleMutation }: ButtonProps) => {
  const { t } = useTranslation()
  const shopify = useAppBridge()
  const { isPublishing } = usePublishingStatus(campaign)

  const isDisabled = hasInvalidProducts || isPublishing || campaignScheduleMutation.isPending
  const isLoading = campaignScheduleMutation.isPending || isPublishing

  const handlePublish = () => {
    if (campaign.status === 'PAUSED' && campaign.preorderTrigger === 'IN_STOCK' && campaign.hasStockLimit) {
      shopify.modal.show('resume-campaign-modal')
      return
    }

    if (campaign.status === 'DRAFT') {
      shopify.modal.show('publish-campaign-modal')
      return
    }

    campaignScheduleMutation.mutate({ action: 'PUBLISH' })
  }

  return (
    <Button variant="primary" onClick={handlePublish} disabled={isDisabled} loading={isLoading}>
      {campaign.status === 'PAUSED' ? t('campaignsManageCtaResume') : t('campaignsManageCtaPublish')}
    </Button>
  )
}

const PauseButton = ({ campaign, campaignScheduleMutation }: ButtonProps) => {
  const { t } = useTranslation()
  const shopify = useAppBridge()
  const { isUnpublishing } = usePublishingStatus(campaign)

  const isDisabled = isUnpublishing || campaignScheduleMutation.isPending
  const isLoading = campaignScheduleMutation.isPending || isUnpublishing

  const handlePause = () => {
    if (campaign.preorderTrigger === 'IN_STOCK' && campaign.hasStockLimit) {
      shopify.modal.show('pause-campaign-modal')
    } else {
      campaignScheduleMutation.mutate({ action: 'UNPUBLISH' })
    }
  }

  return (
    <Button variant="primary" onClick={handlePause} disabled={isDisabled} loading={isLoading}>
      {t('campaignsManageCtaUnpublish')}
    </Button>
  )
}

const CancelButton = ({ campaignScheduleMutation }: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      variant="primary"
      onClick={() => campaignScheduleMutation.mutate({ action: 'CANCEL' })}
      disabled={campaignScheduleMutation.isPending}
      loading={campaignScheduleMutation.isPending}
    >
      {t('campaignsManageCtaCancel')}
    </Button>
  )
}

const ArchiveButton = ({ campaignScheduleMutation }: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="primary"
      onClick={() => {
        const archiveModal = document.getElementById('archive-campaign-modal') as UIModalElement
        archiveModal.show()
      }}
      disabled={campaignScheduleMutation.isPending}
      loading={campaignScheduleMutation.isPending}
    >
      {t('campaignsManageCtaArchive')}
    </Button>
  )
}

const ScheduleButton = ({ hasInvalidProducts, campaignScheduleMutation }: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="primary"
      onClick={() => campaignScheduleMutation.mutate({ action: 'PUBLISH' })}
      disabled={hasInvalidProducts || campaignScheduleMutation.isPending}
      loading={campaignScheduleMutation.isPending}
    >
      {t('campaignsManageCtaSchedule')}
    </Button>
  )
}

export { CampaignPrimaryActions }
