import { Controller, useFormContext } from 'react-hook-form'
import { Box, BlockStack, Divider, FormLayout, TextField } from '@shopify/polaris'
import { ColorPicker } from '@/components/shared/ColorPicker'
import { useTranslation } from 'react-i18next'
import { localisationQueryOptions } from '@/common/queryOptions'
import { useSuspenseQuery } from '@tanstack/react-query'
import { CardHeader } from '../shared/CardHeader'

const PreorderWidgetSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const { data: localisation } = useSuspenseQuery(localisationQueryOptions())

  return (
    <BlockStack gap="400">
      <Box padding="400" paddingBlock="200">
        <BlockStack gap="200">
          <CardHeader title={t('displayPreorderButtonTitle')} />
          <FormLayout>
            <FormLayout.Group>
              <Controller
                name="addToCartButtonText"
                control={control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    autoComplete="off"
                    label={t('displayPreorderButtonLabel')}
                    placeholder={t('displayPreorderButtonPlaceholder')}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={error?.message}
                  />
                )}
              />
            </FormLayout.Group>
            <FormLayout.Group>
              <Controller
                name="addToCartButtonBackgroundColor"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <ColorPicker
                    label={t('displayPreorderButtonBackgroundColorLabel')}
                    placeholder="#ffffff"
                    defaultColor="#ffffff"
                    onChange={onChange}
                    color={value ?? ''}
                    error={error?.message}
                    allowInherit={true}
                  />
                )}
              />
              <Controller
                name="addToCartButtonTextColor"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <ColorPicker
                    label={t('displayPreorderButtonTextColorLabel')}
                    placeholder="#000000"
                    defaultColor="#000000"
                    onChange={onChange}
                    color={value ?? ''}
                    error={error?.message}
                    allowInherit={true}
                  />
                )}
              />
            </FormLayout.Group>
          </FormLayout>
        </BlockStack>
      </Box>
      <Divider />
      <Box padding="400" paddingBlockStart="0" paddingBlockEnd="200">
        <BlockStack gap="200">
          <CardHeader title={t('displayPreorderPurchaseOptionsTitle')} />
          <FormLayout.Group>
            <Controller
              name="sellingPlanBadgeBackgroundColor"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ColorPicker
                  label={t('displayPreorderPurchaseOptionsBackgroundColorLabel')}
                  placeholder="#000000"
                  defaultColor="#000000"
                  onChange={onChange}
                  color={value ?? ''}
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="sellingPlanBadgeTextColor"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <ColorPicker
                  label={t('displayPreorderPurchaseOptionsTextColorLabel')}
                  placeholder="#ffffff"
                  defaultColor="#ffffff"
                  onChange={onChange}
                  color={value ?? ''}
                  error={error?.message}
                />
              )}
            />
          </FormLayout.Group>
        </BlockStack>
      </Box>
      <Divider />
      <Box padding="400" paddingBlockStart="0">
        <BlockStack gap="200">
          <CardHeader title={t('displayPreorderNotesTitle')} />
          <BlockStack gap="400">
            <Controller
              name="partialPaymentNote"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoComplete="off"
                  label={t('displayPreorderNotesPartialPaymentLabel')}
                  value={value ?? ''}
                  onChange={onChange}
                  helpText={t('displayPreorderNotesPartialPaymentDescription', {
                    tag: '{{FINAL_PAYMENT_DATE}}',
                  })}
                  multiline={2}
                  error={error?.message}
                />
              )}
            />
            <Controller
              name="fulfilmentDateNote"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoComplete="off"
                  label={t('displayPreorderNotesFulfillmentDateLabel')}
                  value={value ?? ''}
                  onChange={onChange}
                  helpText={t('displayPreorderNotesFulfillmentDateDescription', {
                    tag: '{{FULFILLMENT_DATE}}',
                    asap: localisation?.asapLabel || 'ASAP',
                    unknown: localisation?.unknownLabel || 'Unknown',
                  })}
                  multiline={2}
                  error={error?.message}
                />
              )}
            />
          </BlockStack>
        </BlockStack>
      </Box>
    </BlockStack>
  )
}

export { PreorderWidgetSettings }
