import { Box, InlineStack, Thumbnail, SkeletonBodyText, Button, Tooltip } from '@shopify/polaris'
import { useFetchProduct } from '@/hooks/useFetchProduct'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Campaign } from '@/common/types.ts'
import { ExternalIcon, ProductIcon } from '@shopify/polaris-icons'

type Props = {
  products: Campaign['products']
  itemCount: number
}

const ProductSkeleton = () => {
  return (
    <Box minWidth="200px" paddingBlockStart="150">
      <InlineStack gap="200" align="space-between" blockAlign="center">
        <SkeletonBodyText lines={1} />
      </InlineStack>
    </Box>
  )
}

const CampaignProductInfo: React.FC<Props> = ({ products, itemCount }) => {
  const { t } = useTranslation()

  const displayedText = t('genericItemsCount', {
    count: itemCount,
  })

  const { queryOptions } = useFetchProduct({ productId: products[0].id })
  const { data, isLoading } = useQuery({ ...queryOptions, enabled: !!(products.length === 1 && products[0]?.id) })

  if (products.length > 1) {
    return displayedText
  }

  if (!products.length || !products[0].id) {
    return null
  }

  const content = () => {
    if (isLoading) {
      return <ProductSkeleton />
    }

    if (!data || !data?.product) {
      return t('genericNa')
    }

    return (
      <Box maxWidth="400">
        <InlineStack gap="200" align="space-between" blockAlign="center">
          <Thumbnail
            source={data?.product?.featuredImage?.url ?? ProductIcon}
            size="extraSmall"
            alt={data?.product?.featuredImage?.altText ?? data?.product?.title}
          />
          {data?.product?.title}
          <Tooltip content={t('genericViewProduct')} hasUnderline={false}>
            <div style={{ display: 'flex' }}>
              <Button
                external
                icon={ExternalIcon}
                variant="plain"
                accessibilityLabel={t('genericViewProduct')}
                url={`shopify://admin/products/${data?.product?.legacyResourceId}`}
                target="_blank"
              />
            </div>
          </Tooltip>
        </InlineStack>
      </Box>
    )
  }

  return content()
}

export { CampaignProductInfo }
