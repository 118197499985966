import { Badge, BadgeProgressValue } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const EmailStatusBadge = ({ status = '', size = 'small' }: { status: string | null; size?: 'small' | 'large' }) => {
  const { t } = useTranslation()
  let progress: BadgeProgressValue
  let label: string
  let tone: 'success' | 'warning' | undefined

  switch (status) {
    case 'pending':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('settingsEmailSenderDnsStatusPending')
      tone = undefined
      break
    case 'verified':
      progress = BadgeProgressValue.Complete
      label = t('settingsEmailSenderDnsStatusVerified')
      tone = 'success'
      break
    case 'not_started':
      progress = BadgeProgressValue.Incomplete
      label = t('settingsEmailSenderDnsStatusUnverified')
      tone = 'warning'
      break
    default:
      progress = BadgeProgressValue.PartiallyComplete
      label = ''
      tone = undefined
  }

  return label ? (
    <Badge progress={progress} tone={tone} size={size}>
      {label}
    </Badge>
  ) : null
}

export { EmailStatusBadge }
