import { useMutateSettings } from '@/hooks/useMutateSettings'
import { BlockStack, Box, Divider, Icon, InlineStack, Layout, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useI18n } from '@shopify/react-i18n'
import { useQuery, useSuspenseQueries } from '@tanstack/react-query'
import { DismissableCard } from '@/components/shared/DismissableCard'

/* https://undraw.co/search */
import ReviewIllustration from '@/illustrations/balloon.svg?react'
import TermsIllustration from '@/illustrations/terms.svg?react'
import HelpIllustration from '@/illustrations/faq.svg?react'
import CancelIllustration from '@/illustrations/customer-manage.svg?react'
import {
  reportsBisQueryOptions,
  reportsBackordersQueryOptions,
  campaignReportsOrdersQueryOptions,
  settingsQueryOptions,
  checkoutProfilesQueryOptions,
} from '@/common/queryOptions'
import { CheckCircleIcon } from '@shopify/polaris-icons'
import { openBeacon } from '@/common/functions'

const ThingsToDo = () => {
  const [i18n] = useI18n()
  const { t } = useTranslation()

  const [{ data: campaignReport }, { data: backorderReport }, { data: backInStockReport }, { data: settings }] = useSuspenseQueries({
    queries: [
      campaignReportsOrdersQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBackordersQueryOptions({
        from: undefined,
        to: undefined,
      }),
      reportsBisQueryOptions({
        from: undefined,
        to: undefined,
      }),
      settingsQueryOptions(),
    ],
  })

  const { data: checkoutProfileId, isError: isCheckoutProfileError } = useQuery(checkoutProfilesQueryOptions())

  const settingsMutation = useMutateSettings()

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutate({ dismissFlags })
  }

  let mainCurrencyOrders = 0
  let mainCurrencyCampaignRevenue = 0
  let mainCurrencyBackorderRevenue = 0
  let mainCurrencyBackInStockRevenue = 0

  if (campaignReport && campaignReport.length) {
    campaignReport.forEach((group) => {
      if (group.currency === settings.currency) {
        mainCurrencyOrders += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
        mainCurrencyCampaignRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })
  }

  if (backorderReport && backorderReport.length) {
    backorderReport.forEach((group) => {
      if (group.currency === settings.currency) {
        mainCurrencyOrders += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
        mainCurrencyBackorderRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })
  }

  if (backInStockReport && backInStockReport.length) {
    backInStockReport.forEach((group) => {
      if (group.currency === settings.currency) {
        mainCurrencyOrders += group.data.reduce((total, current) => total + (current.orders ?? 0), 0)
        mainCurrencyBackInStockRevenue += group.data.reduce((total, current) => total + (current.amount ?? 0), 0)
      }
    })
  }

  const totalRevenue = mainCurrencyBackorderRevenue + mainCurrencyCampaignRevenue + mainCurrencyBackInStockRevenue
  const totalRevenueFormatted = i18n.formatCurrency(totalRevenue, {
    currency: settings?.currency ?? 'USD',
  })

  const dismissFlags = settings?.dismissFlags

  return (
    <Layout.Section>
      <BlockStack gap="400">
        {!dismissFlags?.leaveReview && settings?.currency && totalRevenue > 100 && mainCurrencyOrders >= 3 && (
          <DismissableCard
            className="holographicCard"
            title={t('thingsToDoLeaveReviewTitle')}
            description={t('thingsToDoLeaveReviewDescriptionWithRevenue', {
              revenue: totalRevenueFormatted,
            })}
            illustration={<ReviewIllustration />}
            primaryCta={{
              label: t('thingsToDoLeaveReviewPrimaryCta'),
              props: {
                url: 'https://apps.shopify.com/early-bird-pre-order-manager#modal-show=WriteReviewModal',
                external: true,
              },
            }}
          />
        )}

        {!dismissFlags?.helpCenter && (
          <DismissableCard
            title={t('thingsToDoHelpCenterTitle')}
            description={t('thingsToDoHelpCenterDescription')}
            illustration={<HelpIllustration />}
            primaryCta={{
              label: t('thingsToDoHelpCenterPrimaryCta'),
              props: {
                url: 'https://docs.shopside.com.au/collection/11-early-bird',
                external: true,
              },
            }}
            secondaryCta={{
              label: t('thingsToDoHelpCenterSecondaryCta'),
              props: {
                onClick: () => {
                  openBeacon('ASK')
                },
              },
            }}
            onDismiss={() => onDismiss({ ...dismissFlags, helpCenter: true })}
          />
        )}

        {!dismissFlags?.reviewRules && (
          <DismissableCard
            title={t('thingsToDoReviewRulesTitle')}
            description={t('thingsToDoReviewRulesDescription')}
            illustration={<TermsIllustration />}
            primaryCta={{
              label: t('thingsToDoReviewRulesPrimaryCta'),
              props: {
                url: 'https://help.shopify.com/en/manual/products/purchase-options/pre-orders/setup',
                external: true,
              },
            }}
            onDismiss={() => onDismiss({ ...dismissFlags, reviewRules: true })}
          />
        )}

        {!isCheckoutProfileError && checkoutProfileId && !dismissFlags?.cancelExtension && (
          <DismissableCard
            title={t('thingsToDoCancelExtensionTitle')}
            description={t('thingsToDoCancelExtensionDescription')}
            illustration={<CancelIllustration />}
            primaryCta={{
              label: t('thingsToDoCancelExtensionPrimaryCta'),
              props: {
                url: `https://admin.shopify.com/store/${shopify.config.shop.split('.')[0]}/settings/checkout/editor/profiles/${checkoutProfileId}?page=customer-account-order-status&extensionConfiguration=${import.meta.env.VITE_APP_CANCEL_EXTENSION_ID}&extensionPicker=true`,
                external: true,
              },
            }}
            secondaryCta={{
              label: t('thingsToDoCancelExtensionSecondaryCta'),
              props: {
                url: `https://www.shopside.com.au/earlybird-article/customer-accounts-manage-order-extension`,
                external: true,
              },
            }}
            onDismiss={() => onDismiss({ ...dismissFlags, cancelExtension: true })}
          />
        )}

        <InlineStack align="center" blockAlign="center">
          <Box width="100%" paddingBlock="300">
            <Divider borderColor="border-tertiary" borderWidth="025" />
          </Box>
          <Box background="bg" paddingInlineStart="400" paddingInlineEnd="400" position="absolute">
            <InlineStack wrap={false} gap="200">
              <Box>
                <Icon source={CheckCircleIcon} tone="subdued" />
              </Box>
              <Text variant="headingSm" as="h2" tone="subdued" breakWord={false}>
                {t('thingsToDoFooter')}
              </Text>
            </InlineStack>
          </Box>
        </InlineStack>
      </BlockStack>
    </Layout.Section>
  )
}

export { ThingsToDo }
