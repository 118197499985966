import { Controller, useFormContext } from 'react-hook-form'

import { Box, FormLayout, TextField, Card, Collapsible, Button, BlockStack, Divider } from '@shopify/polaris'

import { CardHeader } from '@/components/shared/CardHeader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const AdvancedSettings = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  const [open, setOpen] = useState(false)

  return (
    <BlockStack gap="400">
      <Card>
        <CardHeader title={t('settingsAdvancedDisplayTitle')} description={t('settingsAdvancedDisplaySubtitle')} />
        <Box paddingBlockStart="300">
          <Controller
            name="customCss"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('settingsFieldCustomCss')}
                multiline={8}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                error={error?.message}
              />
            )}
          />
        </Box>
      </Card>
      <Card padding="0">
        <Box padding="400">
          <CardHeader
            title={t('settingsAdvancedSelectorsTitle')}
            description={t('settingsAdvancedSelectorsSubtitle')}
            actions={
              <Button
                variant="plain"
                onClick={() => {
                  setOpen(!open)
                }}
                accessibilityLabel={open ? t('genericHide') : t('genericShow')}
              >
                {open ? t('genericHide') : t('genericShow')}
              </Button>
            }
          />
        </Box>
        {open && <Divider />}
        <Box>
          <Collapsible open={open} id="custom-selectors" transition={{ duration: '300ms', timingFunction: 'ease-in-out' }} expandOnPrint>
            <Box padding="400">
              <BlockStack gap="400">
                <BlockStack gap="200">
                  <CardHeader title={t('settingsAdvancedSelectorsProductTitle')} />
                  <FormLayout>
                    <Controller
                      name="variantFieldSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorVariant')}
                          helpText={t('settingsFieldSelectorVariantHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="quantityFieldSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorQuantity')}
                          helpText={t('settingsFieldSelectorQuantityHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="addToCartSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorAddToCart')}
                          helpText={t('settingsFieldSelectorAddToCartHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="buyItNowSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorBuyItNow')}
                          helpText={t('settingsFieldSelectorBuyItNowHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="productPriceSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorProductPrice')}
                          helpText={t('settingsFieldSelectorProductPriceHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                  </FormLayout>
                </BlockStack>
              </BlockStack>
            </Box>
            <Divider />
            <Box padding="400">
              <BlockStack gap="400">
                <BlockStack gap="200">
                  <CardHeader title={t('settingsAdvancedSelectorsCollectionTitle')} />
                  <FormLayout>
                    <Controller
                      name="collectionItemsSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorCollectionItem')}
                          helpText={t('settingsFieldSelectorCollectionItemHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="collectionButtonSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorCollectionButton')}
                          helpText={t('settingsFieldSelectorCollectionButtonHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name="collectionItemImageSelector"
                      control={control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <TextField
                          autoComplete="off"
                          label={t('settingsFieldSelectorCollectionImage')}
                          helpText={t('settingsFieldSelectorCollectionImageHelp')}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value ?? ''}
                          error={error?.message}
                        />
                      )}
                    />
                  </FormLayout>
                </BlockStack>
              </BlockStack>
            </Box>
          </Collapsible>
        </Box>
      </Card>
    </BlockStack>
  )
}

export { AdvancedSettings }
