import { useMutateCampaignSchedule } from '@/hooks/useMutateCampaignSchedule'
import { Modal, TitleBar, useAppBridge } from '@shopify/app-bridge-react'
import { Banner, BlockStack, Box, Card, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useSuspenseQuery } from '@tanstack/react-query'
import { settingsQueryOptions } from '@/common/queryOptions'
import { useMutateSettings } from '@/hooks/useMutateSettings'
import { useState } from 'react'

const CampaignArchiveModal = ({ uuid }: { uuid: string }) => {
  const { t } = useTranslation()
  const shopify = useAppBridge()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const campaignScheduleMutation = useMutateCampaignSchedule(uuid)

  const handleConfirmArchive = () => {
    campaignScheduleMutation.mutate({ action: 'ARCHIVE' })
    shopify.modal.hide('archive-campaign-modal')
  }

  // Mutation to modify the campaign schedule
  const settingsMutation = useMutateSettings()

  // Local state to immediately hide banner on dismiss
  const [isLocallyDismissed, setIsLocallyDismissed] = useState(false)

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutateAsync({ dismissFlags })
  }

  return (
    <Modal id="archive-campaign-modal">
      {!settings?.dismissFlags?.archiveModal && !isLocallyDismissed ? (
        <Box padding="400" paddingBlockEnd="0">
          <div className="modalBannerCard">
            <Card padding="0">
              <BlockStack gap="400">
                <Banner
                  tone="info"
                  onDismiss={() => {
                    onDismiss({ ...settings.dismissFlags, archiveModal: true })
                    setIsLocallyDismissed(true)
                  }}
                >
                  <p>{t('campaignArchiveBanner')}</p>
                </Banner>
              </BlockStack>
            </Card>
          </div>
        </Box>
      ) : null}

      <Box padding="400">
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <BlockStack gap="200">
            <Text as="p" breakWord={true} truncate={false}>
              {t('campaignArchiveDescription')}
            </Text>
          </BlockStack>
        </div>
      </Box>
      <TitleBar title={t('campaignArchiveTitle')}>
        <button variant="primary" onClick={handleConfirmArchive}>
          {t('campaignArchiveCta')}
        </button>
        <button
          onClick={() => {
            shopify.modal.hide('archive-campaign-modal')
          }}
        >
          {t('genericCancel')}
        </button>
      </TitleBar>
    </Modal>
  )
}

export { CampaignArchiveModal }
