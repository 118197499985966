import { useForm } from 'react-hook-form'
import { useSuspenseQuery } from '@tanstack/react-query'
import dayjs from '@/common/datetime'
import { produce } from 'immer'
import { FetchCampaignResponse } from '@/common/types'
import { settingsQueryOptions, shopifyResourcePickerQueryOptions } from '@/common/queryOptions'
import { useMutateCampaign } from './useMutateCampaign'
import { campaignFormResolver } from '@/components/preorders/CampaignForm/validation'
import { schema, FormSchema } from '@/components/preorders/CampaignForm/schema'
import { useState } from 'react'
import i18n from 'i18next'

const getProductAndVariantIds = (products?: FetchCampaignResponse['products']): [string[], string[]] => {
  if (!products) return [[], []]

  return products.reduce(
    ([productIds, variantIds], product) => {
      if (!product.variants?.length) {
        productIds.push(product.id)
      } else {
        variantIds.push(...product.variants.map((variant) => variant.id))
      }
      return [productIds, variantIds]
    },
    [[], []] as [string[], string[]]
  )
}

const useCampaignForm = (campaign?: FetchCampaignResponse) => {
  const [errorMessage, setErrorMessage] = useState<String | null>(null)
  const mutateCampaign = useMutateCampaign(campaign?.uuid)
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const [productIds, variantIds] = campaign ? getProductAndVariantIds(campaign.products) : [[], []]
  const { data: campaignProducts } = useSuspenseQuery(shopifyResourcePickerQueryOptions({ productIds, variantIds }))

  const defaultValues: FormSchema = {
    name: '',
    isPublished: false,
    isFulfillmentDelayedPending: false,
    startDate: dayjs().tz(settings?.timezone).startOf('day').toISOString(),
    hasEndDate: false,
    endDate: null,
    preorderTrigger: 'ALWAYS',
    inventoryReserve: 'ON_SALE',
    fulfillmentTrigger: 'EXACT_TIME',
    fulfillmentDate: null,
    hasStockLimit: false,
    stockLimit: null,
    isContinueSellingManaged: false,
    products: [],
    sellingPlans: {
      full: {
        name: '',
        isActive: false,
        hasDiscount: false,
        discountType: 'PERCENTAGE',
        discountAmount: null,
        partialType: 'PERCENTAGE',
        partialAmount: 100,
      },
      partial: {
        name: '',
        isActive: false,
        partialType: 'PERCENTAGE',
        partialAmount: 0,
        hasDiscount: false,
        discountType: 'PERCENTAGE',
        discountAmount: null,
        finalPaymentTrigger: 'EXACT_TIME',
        paymentDueDate: null,
        numberOfDays: null,
      },
    },
  }

  const form = useForm<FormSchema>({
    defaultValues: campaign ? { ...defaultValues, ...campaign, products: campaignProducts } : defaultValues,
    resolver: campaignFormResolver(schema, { productLimit: settings.productLimit }),
    mode: 'onChange',
    shouldFocusError: true,
  })

  const onSubmit = async (data: FormSchema) => {
    try {
      const payload = produce(data, (draft) => {
        // TODO: Is there a better way to do this?
        if (campaign?.sellingPlans.full.uuid) {
          draft.sellingPlans.full.uuid = campaign.sellingPlans.full.uuid
        }
        if (campaign?.sellingPlans.partial.uuid) {
          draft.sellingPlans.partial.uuid = campaign.sellingPlans.partial.uuid
        }
      })

      return await mutateCampaign.mutateAsync(payload)
    } catch (error) {
      console.error('Error submitting campaign', error)
      setErrorMessage(i18n.t('campaignSubmissionError'))
    }
  }

  return { form, onSubmit, mutation: mutateCampaign, errorMessage, setErrorMessage }
}

export default useCampaignForm
