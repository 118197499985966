import { useCallback, useState, Fragment } from 'react'

import { Bleed, BlockStack, Box, Button, Divider, Icon, InlineStack, Pagination, Text, TextField, Thumbnail, Tooltip } from '@shopify/polaris'
import { AlertTriangleIcon, XSmallIcon, ProductIcon, SearchIcon, ExternalIcon } from '@shopify/polaris-icons'

import { ShopifyProduct } from '@/common/types'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'
import { FieldError } from 'react-hook-form'

const perPage = 10

type ProductSelectorProps = {
  type: 'product' | 'variant'
  initialProducts: ShopifyProduct[]
  invalidProductIds?: string[]
  invalidVariantIds?: string[]
  productLimit?: number
  onSelect: (products: ShopifyProduct[]) => void
  onRemove: (product: ShopifyProduct) => void
  error?: FieldError
}

const ResourcePicker = ({ type, initialProducts, invalidProductIds, invalidVariantIds, productLimit, onSelect, onRemove, error }: ProductSelectorProps) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState<string>('')

  const [currentPage, setCurrentPage] = useState(1)

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * perPage
  const indexOfFirstItem = indexOfLastItem - perPage

  const isProductAvailable = (product: ShopifyProduct) => {
    if (invalidProductIds) {
      return !invalidProductIds.includes(product.id)
    }

    return true
  }

  const isAllVariantsAvailable = (product: ShopifyProduct) => {
    if (invalidVariantIds) {
      return !product.variants?.some((variant) => invalidVariantIds.includes(variant.id))
    }

    return true
  }

  const sortedProducts = initialProducts.slice().sort((a, b) => {
    const isProductAvailableA = isProductAvailable(a)
    const isAllVariantsAvailableA = isAllVariantsAvailable(a)
    const isProductAvailableB = isProductAvailable(b)
    const isAllVariantsAvailableB = isAllVariantsAvailable(b)

    if (!isProductAvailableA || !isAllVariantsAvailableA) {
      return -1
    } else if (!isProductAvailableB || !isAllVariantsAvailableB) {
      return 1
    } else {
      return 0
    }
  })

  const currentItems = sortedProducts.slice(indexOfFirstItem, indexOfLastItem)

  const openResourcePicker = useCallback(
    (productId?: string) => {
      const editingProduct = initialProducts.find((product) => product.id === productId) ?? undefined

      shopify
        .resourcePicker({
          multiple: true,
          filter: {
            variants: type === 'variant',
            draft: false,
            hidden: false,
            query: editingProduct ? `id:${editingProduct.id.split('/').pop()}` : undefined,
          },
          selectionIds: editingProduct ? [editingProduct] : initialProducts,
          type: 'product',
          query: searchValue,
          action: 'select',
        })
        .then((selection) => {
          // If it's undefined, the user closed the picker
          if (!selection) return

          if (editingProduct) {
            const modifiedProduct = selection[0]
            const updatedSelection = initialProducts.map((product) => {
              if (product.id === editingProduct.id) {
                return {
                  ...product,
                  variants: modifiedProduct?.variants
                    ? modifiedProduct.variants?.map((variant) => ({
                        id: variant.id!,
                      })) ?? []
                    : [],
                }
              }
              return product
            })
            onSelect(updatedSelection)
          } else {
            const updatedSelection = selection.map((product) => ({
              id: product.id,
              title: product.title,
              variants:
                product.variants?.map((variant) => ({
                  id: variant.id!,
                })) ?? [],
              featuredImage:
                product.images.length > 0
                  ? {
                      url: product.images[0].originalSrc,
                    }
                  : undefined,
              totalVariants: product.totalVariants,
            }))

            onSelect(updatedSelection)
          }
        })
    },
    [onSelect, searchValue]
  )

  const totalProductsAndVariants = initialProducts.reduce((acc, product) => {
    const isAllVariants = product.variants?.length === 0 || product.variants?.length === product.totalVariants

    acc += isAllVariants ? 1 : (product.variants?.length ?? 0) || 1
    return acc
  }, 0)

  return (
    <BlockStack gap="400">
      <TextField
        placeholder={t('productSelectorSearchPlaceholder')}
        autoComplete="off"
        label={t('productSelectorSearchLabel')}
        labelHidden
        value={searchValue}
        prefix={<Icon source={() => <SearchIcon />} />}
        onChange={(val: string) => {
          setSearchValue(val)
          openResourcePicker()
        }}
        connectedRight={
          <Button size="large" onClick={() => openResourcePicker()}>
            {t('genericBrowse')}
          </Button>
        }
        error={error?.message}
      />
      {currentItems.length > 0 && (
        <>
          <Box borderWidth="025" borderColor="border-secondary" borderRadius="200" padding="300">
            <BlockStack gap="400">
              {currentItems.map((product, index) => {
                const isUnavailable = !isProductAvailable(product) || !isAllVariantsAvailable(product)
                const isAllVariants = product.variants?.length === 0 || product.variants?.length === product.totalVariants

                return (
                  <Fragment key={product.id}>
                    <Box>
                      <InlineStack gap="400" align="space-between" blockAlign="center">
                        <InlineStack gap="400">
                          <Thumbnail source={product.featuredImage?.url ?? ProductIcon} size="small" alt={product.title} />
                          <BlockStack gap="0">
                            <InlineStack gap="100" blockAlign="center" align="start">
                              <Text as="span">{product.title}</Text>
                              <Tooltip content={t('genericViewProduct')} hasUnderline={false}>
                                <Link to={`shopify://admin/products/${product.id.split('/').pop()}`} target="_blank" rel="noopener,noreferrer">
                                  <Icon source={() => <ExternalIcon />} tone="subdued" accessibilityLabel={t('genericViewProduct')} />
                                </Link>
                              </Tooltip>
                              {isUnavailable && (
                                <Tooltip content={t('campaignProductsUnavailableTooltip')} hasUnderline={false}>
                                  <Icon source={() => <AlertTriangleIcon />} tone="critical" accessibilityLabel={t('campaignProductsUnavailableTooltip')} />
                                </Tooltip>
                              )}
                            </InlineStack>
                            {(product.totalVariants ?? 0 > 1) && type === 'variant' && (
                              <Text as="p" variant="bodyMd" tone="subdued">
                                (
                                {isAllVariants
                                  ? t('productSelectorAllVariants')
                                  : t('productSelectorSpecificVariants', {
                                      count: product.variants?.length,
                                      total: product.totalVariants,
                                    })}
                                )
                              </Text>
                            )}
                          </BlockStack>
                        </InlineStack>
                        <InlineStack gap="400">
                          {(product.totalVariants ?? 0 > 1) && type === 'variant' && (
                            <Button variant="plain" onClick={() => openResourcePicker(product.id)}>
                              {t('genericEdit')}
                            </Button>
                          )}
                          <Button
                            variant="plain"
                            icon={<Icon source={() => <XSmallIcon />} />}
                            onClick={() => {
                              onRemove(product)
                            }}
                          ></Button>
                        </InlineStack>
                      </InlineStack>
                    </Box>
                    {index < currentItems.length - 1 && (
                      <Bleed marginInline="300">
                        <Divider />
                      </Bleed>
                    )}
                  </Fragment>
                )
              })}
            </BlockStack>
          </Box>
        </>
      )}
      <InlineStack align="space-between" blockAlign="center">
        <Text as="p">
          {t('productSelectorMetaCount', {
            count: totalProductsAndVariants,
            max: productLimit,
          })}
        </Text>
        <Pagination
          label={
            initialProducts.length === 0
              ? undefined
              : t('productSelectorMetaPagination', {
                  start: currentPage * perPage - perPage + 1,
                  end: Math.min(currentPage * perPage, initialProducts.length),
                  count: initialProducts.length,
                })
          }
          hasPrevious={currentPage > 1}
          onPrevious={() => {
            setCurrentPage(currentPage - 1)
          }}
          hasNext={initialProducts.length > currentPage * perPage}
          onNext={() => {
            setCurrentPage(currentPage + 1)
          }}
        />
      </InlineStack>
    </BlockStack>
  )
}

export { ResourcePicker }
