import { useFormContext } from 'react-hook-form'

import { Card } from '@shopify/polaris'

import { ShopifyProduct } from '@/common/types'
import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'
import { ResourcePicker } from '@/components/shared/ResourcePicker'
import { CardSection } from '../CardSection'

const ProductSelection = () => {
  const { t } = useTranslation()
  const { setValue, clearErrors, watch } = useFormContext()

  const bisSelectionType = watch('bisSelectionType')
  const bisProducts = watch('bisProducts') as ShopifyProduct[]

  const onSelect = (selection: ShopifyProduct[]) => {
    setValue('bisProducts', selection, {
      shouldDirty: true,
    })

    clearErrors('bisProducts')
  }

  const onRemove = (product: ShopifyProduct) => {
    const updatedProducts = bisProducts.filter(({ id }) => id !== product.id)

    setValue('bisProducts', updatedProducts, {
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <CardSection>
        <CardHeader
          title={bisSelectionType === 'EXCLUDE' ? t('backInStockProductsExcludedCardTitle') : t('backInStockProductsIncludedCardTitle')}
          description={bisSelectionType === 'EXCLUDE' ? t('backInStockProductsExcludedCardDescription') : t('backInStockProductsIncludedCardDescription')}
        />
        <ResourcePicker type="product" initialProducts={bisProducts} onSelect={onSelect} onRemove={onRemove} />
      </CardSection>
    </Card>
  )
}

export { ProductSelection }
