import { useState, useTransition } from 'react'

import { IndexTable, Text, Card, Link, Box, IndexFilters, TabProps, useSetIndexFiltersMode, EmptySearchResult } from '@shopify/polaris'

import { useTranslation } from 'react-i18next'
import { CampaignStatusBadge } from '@/components/shared/CampaignStatusBadge'
import { formatDateForShopify } from '@/common/datetime.ts'
import { useSuspenseQuery } from '@tanstack/react-query'
import { formatNumber, formatPrice } from '@/common/functions.ts'
import { useNavigate } from '@tanstack/react-router'
import { campaignQueryOptions, settingsQueryOptions } from '@/common/queryOptions'

const noop = () => {}

type CampaignTableRow = {
  uuid: string
  name: string
  orderCount: number
  start: string | undefined
  end: string | undefined
  value: number | null
  status: string
  index: number
}

const CampaignTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [tab, setTab] = useState(0)
  const [isPending, startTransition] = useTransition()
  const [currentFilter, setCurrentFilter] = useState<string | undefined>(undefined)
  const [currentPage, setCurrentPage] = useState<number>(1)

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const {
    data: { data: campaigns = [], total = 0, hasNext = false },
  } = useSuspenseQuery(
    campaignQueryOptions({
      status: currentFilter,
      page: currentPage,
    })
  )

  const changeTabs = (tab: string | undefined) => {
    startTransition(() => {
      setCurrentFilter(tab)
      setCurrentPage(1)
    })
  }

  const changePage = (page: number) => {
    startTransition(() => {
      setCurrentPage(page)
    })
  }

  const TABS = [
    { slug: 'all', label: t('campaignsTableTabAll') },
    { slug: 'DRAFT', label: t('campaignsTableTabDraft') },
    { slug: 'ACTIVE', label: t('campaignsTableTabActive') },
    { slug: 'PAUSED', label: t('campaignsTableTabPaused') },
    { slug: 'SCHEDULED', label: t('campaignsTableTabScheduled') },
    { slug: 'ARCHIVED', label: t('campaignsTableTabArchived') },
  ]

  const tabs: TabProps[] = TABS.map((item, index) => ({
    content: item.label,
    index,
    onAction: () => {
      changePage(1)
      changeTabs(item.slug === 'all' ? undefined : item.slug)
    },
    id: `${item}-${index}`,
    isLocked: true,
  }))

  const { mode, setMode } = useSetIndexFiltersMode()

  const rows =
    campaigns?.map((campaign) => {
      return {
        uuid: campaign.uuid,
        name: campaign.name,
        orderCount: campaign.orderCount ?? 0,
        start: campaign.startDate,
        end: campaign.endDate,
        value: campaign.primaryRevenue ?? null,
        status: campaign.status,
      }
    }) ?? []

  const resourceName = {
    singular: t('campaignsTableResourceName'),
    plural: t('campaignsTableResourceNamePlural'),
  }

  const CampaignTableRow = ({ uuid, name, orderCount, start, end, value, status, index }: CampaignTableRow) => {
    const startFormatted = formatDateForShopify(start, settings.timezone)
    const endFormatted = formatDateForShopify(end, settings.timezone)

    const revenueFormatted = formatPrice(value ?? 0, settings.currency)
    const orderCountFormatted = formatNumber(orderCount)

    return (
      <IndexTable.Row id={uuid} key={uuid} position={index}>
        <IndexTable.Cell className="title-cell">
          <div className="title-wrapper">
            <Link
              monochrome
              dataPrimaryLink
              onClick={() => {
                navigate({ to: `/preorders/$uuid`, params: { uuid } })
              }}
              removeUnderline
            >
              <Box paddingBlockStart="100" paddingBlockEnd="100">
                <Text as="span" fontWeight="medium">
                  {name}
                </Text>
              </Box>
            </Link>
          </div>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span">
            <CampaignStatusBadge status={status} />
          </Text>
        </IndexTable.Cell>

        <IndexTable.Cell>
          <Text as="span">{startFormatted}</Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span">{endFormatted}</Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" alignment="end" numeric>
            {orderCountFormatted}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <Text as="span" alignment="end" numeric>
            {revenueFormatted}
          </Text>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  }

  const EmptySearchResultMarkup = () => {
    return <EmptySearchResult title={t('campaignsTableEmptyTitle')} description={t('campaignsTableEmptyDescription')} withIllustration />
  }

  return (
    <Card padding="0">
      <IndexFilters
        selected={tab}
        onSelect={setTab}
        onQueryChange={noop}
        onQueryClear={noop}
        canCreateNewView={false}
        cancelAction={{
          onAction: noop,
          disabled: true,
          loading: false,
        }}
        filters={[]}
        onClearAll={noop}
        mode={mode}
        setMode={setMode}
        loading={isPending}
        hideFilters
        hideQueryField
        tabs={tabs}
        disableStickyMode={true}
      />
      <IndexTable
        selectable={false}
        resourceName={resourceName}
        itemCount={total}
        headings={[
          { title: t('campaignsTableFieldName') },
          { title: t('campaignsTableFieldStatus') },
          { title: t('campaignsTableFieldStartDate') },
          { title: t('campaignsTableFieldEndDate') },
          { title: t('campaignsTableFieldOrders'), alignment: 'end' },
          {
            title: t('campaignsTableFieldRevenue', {
              currency: settings.currency,
            }),
            alignment: 'end',
          },
        ]}
        hasMoreItems={hasNext}
        emptyState={<EmptySearchResultMarkup />}
        pagination={{
          hasNext,
          onNext: () => changePage(currentPage + 1),
          hasPrevious: currentPage > 1,
          onPrevious: () => changePage(currentPage - 1),
        }}
      >
        {rows.map((row, index) => (
          <CampaignTableRow key={row.uuid} index={index} {...row} />
        ))}
      </IndexTable>
    </Card>
  )
}

export { CampaignTable }
