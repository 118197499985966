import { z } from 'zod'

// Common schemas
const discountSchema = z.object({
  hasDiscount: z.boolean(),
  discountType: z.enum(['PERCENTAGE', 'FIXED']),
  discountAmount: z.number().nullish(),
})

const partialSchema = z.object({
  partialType: z.enum(['PERCENTAGE', 'FIXED']),
  partialAmount: z.number(),
})

// Selling plan schemas
const sellingPlanBaseSchema = z
  .object({
    uuid: z.string().optional(),
    isActive: z.boolean(),
    name: z.string().trim(),
  })
  .merge(discountSchema)
  .merge(partialSchema)

const sellingPlanFullSchema = sellingPlanBaseSchema

const sellingPlanPartialSchema = sellingPlanBaseSchema.extend({
  name: z.string().trim().nullish(),
  partialAmount: z.number().nullish(),
  finalPaymentTrigger: z.enum(['TIME_AFTER_CHECKOUT', 'EXACT_TIME']),
  paymentDueDate: z.string().nullish(),
  numberOfDays: z.number().min(0).nullish(),
})

// Product schema
const productSchema = z.object({
  id: z.string(),
  title: z.string(),
  featuredImage: z
    .object({
      url: z.string(),
    })
    .optional(),
  variants: z.array(
    z.object({
      id: z.string(),
    })
  ),
  totalVariants: z.number().optional(),
})

// Main schema
const schema = z.object({
  isPublished: z.boolean(),
  isFulfillmentDelayedPending: z.boolean(),
  name: z.string().trim(),
  preorderTrigger: z.enum(['ALWAYS', 'IN_STOCK', 'OUT_OF_STOCK']),
  startDate: z.string(),
  hasEndDate: z.boolean(),
  endDate: z.string().nullable(),
  inventoryReserve: z.enum(['ON_SALE', 'ON_FULFILLMENT']),
  fulfillmentTrigger: z.enum(['ASAP', 'EXACT_TIME', 'UNKNOWN']),
  fulfillmentDate: z.string().nullish(),
  hasStockLimit: z.boolean(),
  stockLimit: z.number().nullish(),
  isContinueSellingManaged: z.boolean(),
  sellingPlans: z.object({
    full: sellingPlanFullSchema,
    partial: sellingPlanPartialSchema,
  }),
  products: z.array(productSchema).nullable(),
})

export { schema }
export type FormSchema = z.infer<typeof schema>
