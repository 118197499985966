import { Controller, useFormContext } from 'react-hook-form'

import { BlockStack, Card, ChoiceList } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'
import { Disabled } from '../shared/Disabled'

const CampaignTrigger = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext()
  const isPublished = watch('isPublished')

  const options = [
    {
      label: t('campaignsFieldPreorderTriggerAlwaysLabel'),
      value: 'ALWAYS',
      helpText: t('campaignsFieldPreorderTriggerAlwaysDescription'),
    },
    {
      label: t('campaignsFieldPreorderTriggerInStockLabel'),
      value: 'IN_STOCK',
      helpText: t('campaignsFieldPreorderTriggerInStockDescription'),
    },
    {
      label: t('campaignsFieldPreorderTriggerOutOfStockLabel'),
      value: 'OUT_OF_STOCK',
      helpText: t('campaignsFieldPreorderTriggerOutOfStockDescription'),
    },
  ]

  return (
    <div id="campaignTrigger">
      <Card>
        <BlockStack gap="400">
          <CardHeader title={t('campaignsAvailabilityCardTitle')} />
          <Disabled isDisabled={isPublished} message={t('campaignFieldPreorderTriggeDisabledBanner')}>
            <Controller
              name="preorderTrigger"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ChoiceList title="" choices={options} onChange={(values) => onChange(values[0])} selected={[value]} />
              )}
            />
          </Disabled>
        </BlockStack>
      </Card>
    </div>
  )
}

export { CampaignTrigger }
