// React and related hooks
import { useEffect, useState } from 'react'

// External libraries
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { useQuery, useSuspenseQueries } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Banner, BlockStack, Layout, List, Text } from '@shopify/polaris'

// Internal components
import { PageFrame } from '@/components/shared/PageFrame'
import { CampaignStatusBadge } from '@/components/shared/CampaignStatusBadge'
import { CampaignChart } from '@/components/preorders/CampaignChart'
import { CampaignOrderTable } from '@/components/preorders/CampaignOrderTable'
import { CampaignPublishingQueue } from '@/components/preorders/CampaignPublishingQueue'
import { CampaignFallback } from '@/components/preorders/CampaignFallback'
import { CampaignFulfillmentDelayedQueue } from '@/components/preorders/CampaignFulfillmentDelayedQueue'

// Hooks
import { useMutateCampaignSchedule } from '@/hooks/useMutateCampaignSchedule'
import { useMutateCampaign } from '@/hooks/useMutateCampaign'

// Utility functions and configurations
import dayjs from '@/common/datetime'
import {
  campaignOrdersQueryOptions,
  campaignQueryOptions,
  campaignReportsOrdersQueryOptions,
  settingsQueryOptions,
  campaignUnavailableProductsQueryOptions,
} from '@/common/queryOptions'
import { buildAdminUrl } from '@/common/functions'
import { CampaignPrimaryActions } from '@/components/preorders/CampaignPrimaryActions'
import { AppEmbedBlock } from '@/components/dashboard/AppEmbedBlock'
import { useMutateSettings } from '@/hooks/useMutateSettings'
import { CampaignResumeModal } from '@/components/preorders/CampaignResumeModal'
import { CampaignPauseModal } from '@/components/preorders/CampaignPauseModal'
import { CampaignArchiveModal } from '@/components/preorders/CampaignArchiveModal'
import { CampaignPublishModal } from '@/components/preorders/CampaignPublishModal'

const FAST_CAMPAIGN_STATUSES = ['PROCESSING', 'PROCESSING_PRODUCTS', 'PROCESSING_CAMPAIGN']
const FAST_MESSAGE_QUEUE_STATUSES = ['SCHEDULED', 'QUEUEING', 'SENDING']
const DISABLED_STATUSES = ['PROCESSING', 'PROCESSING_PRODUCTS', 'PROCESSING_CAMPAIGN', 'ARCHIVED']

export const Route = createFileRoute('/preorders/$uuid/')({
  loader: async ({ params, context: { queryClient }, route: { path } }) => {
    try {
      Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(campaignQueryOptions({ uuid: params.uuid })),
        queryClient.ensureQueryData(
          campaignReportsOrdersQueryOptions({
            uuid: params.uuid,
            from: undefined,
            to: undefined,
          })
        ),
        queryClient.ensureQueryData(
          campaignOrdersQueryOptions({
            uuid: params.uuid,
            financialStatus: undefined,
            fulfillmentStatus: undefined,
            page: 1,
          })
        ),
      ])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <CampaignFallback />
  },
  component: Index,
})

function Index() {
  const { t } = useTranslation()
  const [isPreorderDelayedBannerDismissed, setIsPreorderDelayedBannerDismissed] = useState(false)
  const [isInfoBannerDismissed, setIsInfoBannerDismissed] = useState(false)

  const { uuid } = useParams({ strict: false }) as { uuid: string }
  const [campaignQueryRefetchInterval, setCampaignQueryRefetchInterval] = useState<number | false>(false)

  const [
    {
      data: {
        data: [campaign],
      },
    },
    { data: settings },
  ] = useSuspenseQueries({
    queries: [{ ...campaignQueryOptions({ uuid }), refetchInterval: campaignQueryRefetchInterval, refetchIntervalInBackground: true }, settingsQueryOptions()],
  })

  const { data: unavailableProducts } = useQuery(
    campaignUnavailableProductsQueryOptions({
      uuid,
      from: campaign.startDate ?? undefined,
      to: campaign.hasEndDate ? campaign?.endDate ?? undefined : undefined,
    })
  )

  const settingsMutation = useMutateSettings()

  // Mutation to modify the campaign schedule
  const campaignScheduleMutation = useMutateCampaignSchedule(uuid)
  const campaignMutation = useMutateCampaign(uuid)

  // Init our hooks before conditional logic
  const navigate = useNavigate()

  // Change the refetch interval based on the campaign status
  useEffect(() => {
    // No data yet, just bail
    if (!campaign.status) return

    if (FAST_CAMPAIGN_STATUSES.includes(campaign.status) || FAST_MESSAGE_QUEUE_STATUSES.includes(campaign.latestPreorderDelayed?.queueStatus)) {
      setCampaignQueryRefetchInterval(3000)
    } else {
      setCampaignQueryRefetchInterval(false)
    }
  }, [campaign.status, campaign.latestPreorderDelayed?.queueStatus])

  const campaignProductIds = campaign.products?.filter((product) => product.variants.length === 0).map((product) => product.id) ?? []
  const campaignVariantIds = campaign.products?.flatMap((product) => product.variants.map((variant) => variant.id)) ?? []
  const invalidProducts = unavailableProducts?.products ? unavailableProducts?.products.filter((product) => campaignProductIds.includes(product.productId)) : []
  const invalidVariants = unavailableProducts?.variants ? unavailableProducts?.variants.filter((variant) => campaignVariantIds.includes(variant.variantId)) : []

  // Get an array of campaign names that are unavailable
  const invalidCampaigns = [
    ...new Map(
      [
        ...invalidProducts.map(({ campaignName, campaignId }) => [campaignId, { campaignName, campaignId }]),
        ...invalidVariants.map(({ campaignName, campaignId }) => [campaignId, { campaignName, campaignId }]),
      ].reduce((acc, [campaignId, campaign]) => {
        if (!acc.has(campaignId)) {
          acc.set(campaignId, campaign)
        }
        return acc
      }, new Map())
    ).values(),
  ]

  // Handy status flags
  const isProcessing = DISABLED_STATUSES.includes(campaign.status)

  const hasInvalidProducts = invalidProducts.length > 0 || invalidVariants.length > 0
  const endDatePassed = campaign.hasEndDate && campaign.endDate && dayjs().diff(dayjs(campaign.endDate), 'second') > 0

  const dismissPreorderDelayedBanner = () => {
    setIsPreorderDelayedBannerDismissed(true)
    campaignMutation.mutate({ isFulfillmentDelayedPending: false })
  }

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutate({ dismissFlags })
    setIsInfoBannerDismissed(true)
  }

  return (
    <PageFrame
      title={campaign.name ?? t('campaignsDashTitle')}
      subtitle={t('campaignsDashSubtitle')}
      titleMetadata={<CampaignStatusBadge status={campaign.status} withProgress={false} />}
      primaryAction={<CampaignPrimaryActions campaign={campaign} isDisabled={isProcessing} hasInvalidProducts={hasInvalidProducts} />}
      secondaryActions={[
        {
          content: t('genericEdit'),
          disabled: isProcessing,
          onAction: () =>
            navigate({
              to: `/preorders/$uuid/settings`,
              params: {
                uuid,
              },
            }),
        },
      ]}
      actionGroups={[
        {
          title: t('genericMoreActions'),
          actions: [
            {
              content: t('campaignsManageEditDisplayCta'),
              disabled: isProcessing,
              onAction: () => {
                navigate({
                  to: `/preorders/$uuid/display`,
                  params: {
                    uuid,
                  },
                })
              },
            },
            {
              content: t('campaignsManageReportCta'),
              onAction: () => {
                navigate({
                  to: `/preorders/$uuid/units-sold`,
                  params: {
                    uuid,
                  },
                })
              },
            },
          ],
        },
      ]}
      backAction={{
        content: t('campaignsTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/preorders` })
        },
      }}
    >
      {campaign.isPublished && campaign.status !== 'ARCHIVED' && <AppEmbedBlock />}
      {!campaign.isPublished && hasInvalidProducts && campaign.status !== 'ARCHIVED' && (
        <Layout.Section>
          <Banner
            title={t('campaignsUnavailableProductsTitle')}
            action={{
              content: t('campaignsUnavailableProductsCta'),
              onAction: async () => {
                /* @ts-ignore */
                await shopify.saveBar.leaveConfirmation()

                navigate({
                  to: `/preorders/$uuid/settings`,
                  params: {
                    uuid,
                  },
                })
              },
            }}
            tone="warning"
          >
            <BlockStack gap="200">
              <Text as="p" variant="bodyMd">
                {t('campaignsUnavailableProductsDescription')}
              </Text>
              <List type="bullet">
                {invalidCampaigns.map(({ campaignId, campaignName }) => (
                  <List.Item key={campaignId}>
                    <a
                      href={buildAdminUrl(`preorders/${campaignId}/settings`)}
                      onClick={async (e) => {
                        e.preventDefault()
                        navigate({
                          to: `/preorders/$uuid/settings`,
                          params: {
                            uuid: campaignId,
                          },
                        })
                      }}
                    >
                      {campaignName}
                    </a>
                  </List.Item>
                ))}
              </List>
            </BlockStack>
          </Banner>
        </Layout.Section>
      )}

      {campaign.status === 'ARCHIVED' && !settings?.dismissFlags?.archivedCampaign && !isInfoBannerDismissed && (
        <Layout.Section>
          <Banner onDismiss={() => onDismiss({ ...settings.dismissFlags, archivedCampaign: true })}>
            <p>
              {t('campaignsArchivedDescription', {
                date: dayjs(campaign.archivedAt)
                  .tz(settings.timezone)
                  .format(`D MMM YYYY [${t('datetimeAt')}] h:mma`),
              })}
            </p>
          </Banner>
        </Layout.Section>
      )}

      {campaign.status === 'SCHEDULED' && !settings?.dismissFlags?.scheduledCampaign && !isInfoBannerDismissed ? (
        <Layout.Section>
          <Banner onDismiss={() => onDismiss({ ...settings.dismissFlags, scheduledCampaign: true })}>
            <p>
              {t('campaignsScheduledDescription', {
                date: dayjs(campaign.startDate)
                  .tz(settings.timezone)
                  .format(`D MMM YYYY [${t('datetimeAt')}] h:mma`),
              })}
            </p>
          </Banner>
        </Layout.Section>
      ) : (
        <CampaignPublishingQueue
          status={campaign.status}
          isContinueSellingManaged={campaign.isContinueSellingManaged}
          productCount={campaignProductIds.length + campaignVariantIds.length}
        />
      )}

      {campaign?.latestPreorderDelayed?.queueStatus === 'ERROR' ? (
        <Layout.Section>
          <Banner tone="critical" title={t('campaignDelayedChecklistErrorTitle')}>
            <p>{t('campaignDelayedChecklistErrorDescription')}</p>
          </Banner>
        </Layout.Section>
      ) : (
        <>
          {campaign.latestPreorderDelayed && (
            <CampaignFulfillmentDelayedQueue status={campaign.latestPreorderDelayed.queueStatus} orderCount={campaign.preordersCount} />
          )}
        </>
      )}

      {campaign.isFulfillmentDelayedPending && !isPreorderDelayedBannerDismissed && (
        <Layout.Section>
          <Banner
            onDismiss={dismissPreorderDelayedBanner}
            title={t('campaignDelayedTitle')}
            action={{
              content: t('campaignDelayedCta'),
              onAction: () => {
                campaignScheduleMutation.mutate({ action: 'DELAY_FULFILLMENT' })
              },
              loading: campaignScheduleMutation.isPending,
            }}
          >
            <p>
              {t('campaignDelayedDescription', {
                date: dayjs(campaign.fulfillmentDate).tz(settings.timezone).format(`D MMM YYYY`),
              })}
            </p>
          </Banner>
        </Layout.Section>
      )}

      {campaign.status === 'ACTIVE' && campaign.hasEndDate && campaign.endDate && !settings?.dismissFlags?.endingCampaign && !isInfoBannerDismissed && (
        <Layout.Section>
          <Banner onDismiss={() => onDismiss({ ...settings.dismissFlags, endingCampaign: true })}>
            <p>
              {t('campaignsWillEndDescription', {
                date: dayjs(campaign.endDate)
                  .tz(settings.timezone)
                  .format(`D MMM YYYY [${t('datetimeAt')}] h:mma`),
              })}
            </p>
          </Banner>
        </Layout.Section>
      )}

      {campaign.status === 'PAUSED' && endDatePassed && !settings?.dismissFlags?.pausedEndedCampaign && !isInfoBannerDismissed && (
        <Layout.Section>
          <Banner onDismiss={() => onDismiss({ ...settings.dismissFlags, pausedEndedCampaign: true })}>
            <p>
              {t('campaignsPausedEndedDescription', {
                date: dayjs(campaign.endDate)
                  .tz(settings.timezone)
                  .format(`D MMM YYYY [${t('datetimeAt')}] h:mma`),
              })}
            </p>
          </Banner>
        </Layout.Section>
      )}

      <Layout.Section>
        <CampaignChart uuid={uuid} campaign={campaign} />
      </Layout.Section>
      <Layout.Section>
        <CampaignOrderTable uuid={uuid} />
      </Layout.Section>

      <CampaignResumeModal uuid={uuid} stockLimit={campaign.stockLimit} />
      <CampaignPauseModal uuid={uuid} />
      <CampaignArchiveModal uuid={uuid} />
      <CampaignPublishModal uuid={uuid} />
    </PageFrame>
  )
}
