import { BlockStack, Box, Grid, InlineGrid, ProgressBar, SkeletonBodyText, Spinner, Text } from '@shopify/polaris'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '@/common/functions'

/**
 * Reusable wrapper for the metric bar
 * @returns React.ReactNode
 */
type UsageBarProps = {
  planName: string
  blocks: React.ReactNode[]
}

const UsageBar = ({ blocks, planName }: UsageBarProps) => {
  const { t } = useTranslation()
  const activeBlocks = blocks.filter((b) => !!b) // remove non-items

  return (
    <BlockStack gap="400" align="center" inlineAlign="stretch">
      <Grid
        columns={{
          xs: 4,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
        }}
      >
        <Grid.Cell
          columnSpan={{
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1,
          }}
        >
          <PlanBlock title={t('usageStatsPlanTitle')} currentPlan={planName} />
        </Grid.Cell>

        <Grid.Cell
          columnSpan={{
            xs: 3,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
          }}
        >
          <Box width="100%">
            <InlineGrid columns={2} gap="400">
              {activeBlocks.map((block, index) => {
                if (block) {
                  return <Fragment key={index}>{block}</Fragment>
                }
              })}
            </InlineGrid>
          </Box>
        </Grid.Cell>
      </Grid>
    </BlockStack>
  )
}

/**
 * Reusable block wrapper
 * @returns React.ReactNode
 */
type MetricBlockProps = {
  title: string
  children?: React.ReactNode
}

const MetricBlock = ({ title, children }: MetricBlockProps) => {
  return (
    <Box>
      <BlockStack gap="100">
        <MetricBlockHeader title={title} />
        {children}
      </BlockStack>
    </Box>
  )
}

/**
 * Reusable block title
 * @returns React.ReactNode
 */
type MetricBlockHeaderProps = {
  title: string
}

const MetricBlockHeader = ({ title }: MetricBlockHeaderProps) => {
  return (
    <Text as="span" variant="bodySm">
      {title}
    </Text>
  )
}

/**
 * Display the current plan with a link to upgrade
 * @returns React.ReactNode
 */

type PlanBlockProps = {
  title: string
  currentPlan?: string
}

const PlanBlock = ({ currentPlan, title }: PlanBlockProps) => {
  return (
    <MetricBlock title={title}>
      {currentPlan ? (
        <Text as="p" variant="headingMd">
          {currentPlan}
        </Text>
      ) : (
        <SkeletonBodyText lines={1} />
      )}
    </MetricBlock>
  )
}

/**
 * Display the used and total progress with a progress bar
 * @returns React.ReactNode
 */

type ProgressBlockProps = {
  title: string
  used?: number
  total?: number
}

const ProgressBlock = ({ title, used, total }: ProgressBlockProps) => {
  // Dank, but TS can't seem to reckon with this otherwise?
  let progressTone: 'highlight' | 'critical' = 'highlight'
  let progress = used ?? 0

  if (total && total > 0) {
    if (progress / total < 0.75) {
      progressTone = 'highlight'
    } else {
      progressTone = 'critical'
    }
  }

  const Label = () => {
    if (used === undefined) {
      return <Spinner size="small" />
    }

    const usedFormatted = formatNumber(used)
    const totalFormatted = total ? formatNumber(total) : undefined

    return (
      <Text as="p" variant="headingMd">
        {usedFormatted} {totalFormatted && `/ ${totalFormatted}`}
      </Text>
    )
  }

  return (
    <MetricBlock title={title}>
      <BlockStack gap="200">
        <Label />
        <ProgressBar progress={total && total > 0 ? (progress / total) * 100 : 0} size="small" tone={progressTone} />
      </BlockStack>
    </MetricBlock>
  )
}

/**
 * Display a single value
 * @returns React.ReactNode
 */

type ValueBlockProps = {
  title: string
  value: string | ReactNode
}

const ValueBlock = ({ title, value }: ValueBlockProps) => {
  return (
    <MetricBlock title={title}>
      <Text as="span" variant="bodyLg" fontWeight="semibold">
        {value}
      </Text>
    </MetricBlock>
  )
}

UsageBar.ProgressBlock = ProgressBlock
UsageBar.PlanBlock = PlanBlock
UsageBar.ValueBlock = ValueBlock

export { UsageBar }
