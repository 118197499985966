import { Controller, useFormContext } from 'react-hook-form'
import { Box, FormLayout, Card, ChoiceList, Banner } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { CardHeader } from '@/components/shared/CardHeader'
import { Disabled } from '@/components/shared/Disabled'

const CampaignInventoryPolicy = () => {
  const { t } = useTranslation()
  const { control, watch } = useFormContext()

  const inventoryPolicy = watch('isContinueSellingManaged')
  const preorderTrigger = watch('preorderTrigger')
  const isPublished = watch('isPublished')

  return (
    <Card>
      <CardHeader
        title={preorderTrigger !== 'IN_STOCK' ? t('campaignInventoryPolicyCardTitle') : t('campaignInventoryPolicyStockLimitCardTitle')}
        description={preorderTrigger !== 'IN_STOCK' ? t('campaignInventoryPolicyCardDescription') : t('campaignInventoryPolicyStockLimitCardDescription')}
      />
      <Box paddingBlockStart="300">
        {!inventoryPolicy && (
          <Box paddingBlockEnd="300">
            <Banner tone="warning">
              <p>{preorderTrigger !== 'IN_STOCK' ? t('campaignInventoryPolicyInlineWarning') : t('campaignInventoryPolicyStockLimitInlineWarning')}</p>
            </Banner>
          </Box>
        )}
        <FormLayout>
          <Disabled isDisabled={isPublished} message={t('campaignInventoryPolicyDisabledBanner')}>
            <Controller
              name="isContinueSellingManaged"
              control={control}
              render={({ field: { onChange, value } }) => {
                const stringValue = value.toString()
                return (
                  <ChoiceList
                    title=""
                    choices={[
                      { label: t('campaignFieldIsContinueSellingManagedNo'), value: 'false' },
                      { label: t('campaignFieldIsContinueSellingManagedYes'), value: 'true' },
                    ]}
                    onChange={(values) => onChange(values[0] === 'true')}
                    selected={[stringValue]}
                  />
                )
              }}
            />
          </Disabled>
        </FormLayout>
      </Box>
    </Card>
  )
}

export { CampaignInventoryPolicy }
