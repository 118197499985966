import { FulfillmentStatus } from '@/common/types'
import { Badge, BadgeProgressValue } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const FulfillmentBadge = ({ fulfillmentStatus }: { fulfillmentStatus: FulfillmentStatus }) => {
  const { t } = useTranslation()

  let progress: BadgeProgressValue
  let label: string
  let tone: 'warning' | 'attention' | 'info' | undefined

  switch (fulfillmentStatus) {
    case 'FULFILLED':
    case 'CLOSED':
      progress = BadgeProgressValue.Complete
      label = t('ordersTableFulfillmentStatusFulfilled')
      tone = undefined
      break
    case 'UNFULFILLED':
    case 'RESTOCKED':
    case 'OPEN':
      progress = BadgeProgressValue.Incomplete
      label = t('ordersTableFulfillmentStatusUnfulfilled')
      tone = 'attention'
      break
    case 'PARTIALLY_FULFILLED':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('ordersTableFulfillmentStatusPartiallyFulfilled')
      tone = 'warning'
      break
    case 'IN_PROGRESS':
    case 'PENDING_FULFILLMENT':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('ordersTableFulfillmentStatusInProgress')
      tone = 'info'
      break
    case 'ON_HOLD':
      progress = BadgeProgressValue.Incomplete
      label = t('ordersTableFulfillmentStatusOnHold')
      tone = 'warning'
      break
    case 'SCHEDULED':
      progress = BadgeProgressValue.Incomplete
      label = t('ordersTableFulfillmentStatusScheduled')
      tone = 'info'
      break
    default:
      progress = BadgeProgressValue.PartiallyComplete
      label = ''
      tone = undefined
  }

  return label ? (
    <Badge progress={progress} tone={tone}>
      {label}
    </Badge>
  ) : null
}

export { FulfillmentBadge }
