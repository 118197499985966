import { useTranslation } from 'react-i18next'
import { formatNumber } from '@/common/functions.ts'

const ConversionRatePercentage = ({ conversionRate = 0 }: { conversionRate: number }) => {
  const { t } = useTranslation()

  if (!conversionRate) {
    return t('genericPercentage', {
      percentage: 0,
    })
  }

  /*let tooltip: string = ''
  const conversionRateDelta: number = conversionRate - BIS_AVG_CONVERSION_RATE

  if (conversionRateDelta < -8) {
    tooltip = t('backInStockConversionRateTooltipBad')
  } else if (conversionRateDelta >= -8 && conversionRateDelta < -5) {
    tooltip = t('backInStockConversionRateTooltipLow')
  } else if (conversionRateDelta >= -5 && conversionRateDelta < -2) {
    tooltip = t('backInStockConversionRateTooltipAverage')
  } else if (conversionRateDelta >= -2 && conversionRateDelta < 5) {
    tooltip = t('backInStockConversionRateTooltipGood')
  } else if (conversionRateDelta >= 5) {
    tooltip = t('backInStockConversionRateTooltipHigh')
  }

  return tooltip ? (
    <Tooltip content={tooltip}>
      <Text as="span">
        {t('genericPercentage', {
          percentage: formatNumber(conversionRate),
        })}
      </Text>
    </Tooltip>
  ) : null*/

  return t('genericPercentage', {
    percentage: formatNumber(conversionRate),
  })
}

export { ConversionRatePercentage }
