import { Controller, useFormContext } from 'react-hook-form'

import { BlockStack, Card, Tabs, Box, TextField, Layout, Grid, Checkbox } from '@shopify/polaris'

import { useCallback, useEffect, useState, useTransition } from 'react'
import { openBeacon, replaceInTemplate } from '@/common/functions'
import { Trans, useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { useSuspenseQuery } from '@tanstack/react-query'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { localisationQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import dayjs from '@/common/datetime'
import { EmailPreview } from '@/components/shared/EmailPreview'

const PreOrderDelayed = ({ defaultTemplateHtml, isDisabled }: { defaultTemplateHtml: string; isDisabled: boolean }) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const [_, startTransition] = useTransition()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const hasCustomEmailTemplatesFeature = settings.featureCustomEmailTemplates

  const TABS = [
    { id: '0', content: t('emailTemplatesTabEdit') },
    { id: '1', content: t('emailTemplatesTabTemplate') },
  ]

  const [selected, setSelected] = useState(0)

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    startTransition(() => {
      setSelected(selectedTabIndex)
    })
  }, [])

  const editorMarkup = () => {
    if (selected === 0) {
      return <Editor />
    }
    if (selected === 1) {
      return <Template isDisabled={!hasCustomEmailTemplatesFeature} defaultTemplateHtml={defaultTemplateHtml} />
    }
  }

  const templateHtml =
    watch('preOrderDelayed.hasCustomTemplate') && hasCustomEmailTemplatesFeature ? watch('preOrderDelayed.templateHtml') : defaultTemplateHtml

  return (
    <Layout.Section>
      <SectionTitle title={t('emailTemplatesPreorderDelayedTitle')} description={t('emailTemplatesPreorderDelayedDescription')} />
      <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
          <Card padding="0">
            <Tabs selected={selected} onSelect={handleTabChange} fitted tabs={TABS}>
              <Box padding="400" paddingBlockStart="200">
                <Disabled isDisabled={isDisabled}>{editorMarkup()}</Disabled>
              </Box>
            </Tabs>
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
          <Card>
            <Preview templateHtml={templateHtml} shopName={settings.shopName} />
          </Card>
        </Grid.Cell>
      </Grid>
    </Layout.Section>
  )
}

const Preview = ({ templateHtml, shopName }: { templateHtml: string; shopName: string }) => {
  const { watch } = useFormContext()
  const { data: localisation } = useSuspenseQuery(localisationQueryOptions())
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const body = watch('preOrderDelayed.body')

  let parsedBody = body
    ? replaceInTemplate(body, [
        {
          find: '{{NEW_FULFILLMENT_DATE}}',
          replace: dayjs()
            .tz(settings.timezone)
            .locale(localisation.dateLocale)
            .add(14, 'day')
            .format(localisation?.dateFormat ?? 'YYYY-MM-DD'),
        },
        {
          find: '{{DELAYED_PRODUCTS}}',
          replace: `<ul>
        <li>Product 1</li>
        <li>Product 2</li>
        <li>Product 3</li>
        </ul>`,
        },
      ])
    : ''

  parsedBody = parsedBody
    // Remove leading and trailing whitespace
    .trim()
    // Remove unwanted whitespace between paragraphs
    .replace(/\n\s*\n/g, '\n\n')
    // Split by double newlines to identify paragraphs
    .split(/\n\n/)
    .map((paragraph) => {
      // Check if the paragraph contains HTML tags
      if (!/<(?:.|\n)*?>/.test(paragraph)) {
        // If it doesn't contain HTML tags, wrap it in <p> tags
        return `<p>${paragraph.trim()}</p>`
      }
      // If it contains HTML tags, leave it unchanged
      return paragraph
    })
    .join('')

  let parsedHtml = replaceInTemplate(templateHtml ?? '', [
    { find: '{{SHOP_NAME}}', replace: shopName ?? '' },
    { find: '{{ORDER_NAME}}', replace: '#1001' },
    { find: '{{TITLE}}', replace: watch('preOrderDelayed.title') },
    { find: '{{BODY}}', replace: parsedBody },
    { find: '{{BUTTON_TEXT}}', replace: watch('preOrderDelayed.primaryButtonText') ?? '' },
    { find: '{{BUTTON_URL}}', replace: 'javascript:;' },
  ])

  return <EmailPreview subject={watch('preOrderDelayed.subject')} htmlContent={parsedHtml} />
}

const Editor = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  // const insertTag = (tag: string) => {
  //   const inputElement = document.querySelector('textarea')
  //   if (!inputElement) return

  //   const body = watch('preOrderDelayed.body')
  //   const cursorPosition = inputElement.selectionStart
  //   const newBody = body.substring(0, cursorPosition) + tag + body.substring(cursorPosition)
  //   setValue('preOrderDelayed.body', newBody)

  //   // Calculate new cursor position
  //   const newCursorPosition = cursorPosition + tag.length

  //   // Update textarea value and move cursor
  //   inputElement.value = newBody
  //   inputElement.setSelectionRange(newCursorPosition, newCursorPosition)
  //   inputElement.focus()
  // }

  return (
    <BlockStack gap="200">
      <Controller
        name="preOrderDelayed.subject"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('emailTemplatesFieldSubject')}
            maxLength={256}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error?.message}
          />
        )}
      />
      <Controller
        name="preOrderDelayed.title"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField autoComplete="off" label={t('emailTemplatesFieldTitle')} onChange={onChange} onBlur={onBlur} value={value} error={error?.message} />
        )}
      />
      <BlockStack gap="200">
        <Controller
          name="preOrderDelayed.body"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="preOrderDelayed.body"
              autoComplete="off"
              label={t('emailTemplatesFieldBody')}
              helpText={
                <Trans t={t} i18nKey="emailTemplatesFieldBodyHelpText">
                  .
                  <a target="_blank" href="https://www.shopside.com.au/earlybird-article/dynamic-email-template-tags">
                    .
                  </a>
                  .
                </Trans>
              }
              multiline={5}
              maxHeight={300}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error?.message}
            />
          )}
        />
      </BlockStack>
      <Controller
        name="preOrderDelayed.primaryButtonText"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('emailTemplatesFieldPrimaryCta')}
            helpText={t('emailTemplatesFieldPrimaryCtaHelpText')}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={100}
            value={value}
            error={error?.message}
          />
        )}
      />
    </BlockStack>
  )
}

const Template = ({ isDisabled, defaultTemplateHtml }: { isDisabled: boolean; defaultTemplateHtml: string }) => {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()

  const hasCustomTemplate = watch('preOrderDelayed.hasCustomTemplate')
  const templateHtml = watch('preOrderDelayed.templateHtml')

  useEffect(() => {
    if (hasCustomTemplate && !templateHtml) {
      setValue('preOrderDelayed.templateHtml', defaultTemplateHtml)
    }
  }, [hasCustomTemplate])

  return (
    <>
      <Disabled
        isDisabled={isDisabled}
        isLowerOpacity={true}
        message={
          <p>
            <Trans t={t} i18nKey="genericContactUsBanner">
              .
              <a
                href="#"
                onClick={async () => {
                  openBeacon('ASK')
                }}
              >
                .
              </a>
              .
            </Trans>
          </p>
        }
      >
        <BlockStack gap="200">
          <Controller
            name="preOrderDelayed.hasCustomTemplate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('emailTemplatesFieldHasCustomTemplate')}
                helpText={t('emailTemplatesFieldHasCustomTemplateHelp')}
                checked={hasCustomTemplate && value ? value : false}
                onChange={onChange}
              />
            )}
          />
          {hasCustomTemplate && (
            <>
              {isDisabled ? (
                <TextField autoComplete="off" label={t('emailTemplatesTabTemplate')} multiline={14} maxHeight={300} placeholder={defaultTemplateHtml} />
              ) : (
                <Controller
                  name="preOrderDelayed.templateHtml"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      autoComplete="off"
                      label={t('emailTemplatesTabTemplate')}
                      multiline={14}
                      maxHeight={300}
                      onChange={onChange}
                      value={value}
                      placeholder={defaultTemplateHtml}
                      error={error?.message}
                      readOnly={!hasCustomTemplate}
                    />
                  )}
                />
              )}
            </>
          )}
        </BlockStack>
      </Disabled>
    </>
  )
}

export { PreOrderDelayed }
