import { useMutateCampaignSchedule } from '@/hooks/useMutateCampaignSchedule'
import { Modal, TitleBar, useAppBridge } from '@shopify/app-bridge-react'
import { BlockStack, Box, Checkbox, Link, List, Text } from '@shopify/polaris'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { openBeacon } from '@/common/functions.ts'

const CampaignPublishModal = ({ uuid }: { uuid: string }) => {
  const { t } = useTranslation()
  const shopify = useAppBridge()
  const [didConfirm, setDidConfirm] = useState(false)

  const campaignScheduleMutation = useMutateCampaignSchedule(uuid)

  const handleConfirmPublish = () => {
    shopify.modal.hide('publish-campaign-modal')
    campaignScheduleMutation.mutate({ action: 'PUBLISH' })
  }

  return (
    <Modal id="publish-campaign-modal">
      <Box padding="400">
        <BlockStack gap="400">
          <Text as="p">
            <Trans t={t} i18nKey="campaignsManagePublishModalDescription">
              .
              <a target="_blank" href="https://docs.shopside.com.au/article/53-what-are-the-requirements-for-pre-orders-on-shopify">
                .
              </a>
              .
            </Trans>
          </Text>
          <List>
            <List.Item>
              <Text as="p">{t('campaignsManagePublishModalListItem1')}</Text>
            </List.Item>
            <List.Item>
              <Text as="p">{t('campaignsManagePublishModalListItem2')}</Text>
            </List.Item>
            <List.Item>
              <Text as="p">
                <Trans t={t} i18nKey="campaignsManagePublishModalListItem3">
                  .
                  <Link
                    onClick={() => {
                      shopify.modal.hide('publish-campaign-modal')
                      openBeacon('ASK')
                    }}
                  >
                    .
                  </Link>
                  .
                </Trans>
              </Text>
            </List.Item>
          </List>
          <Checkbox label={t('campaignsManagePublishModalConfirmLabel')} checked={didConfirm} onChange={() => setDidConfirm(!didConfirm)} />
        </BlockStack>
      </Box>

      <TitleBar title={t('campaignsManagePublishModalTitle')}>
        <button variant="primary" onClick={handleConfirmPublish} disabled={!didConfirm}>
          {t('campaignsManagePublishModalPublishCta')}
        </button>
        <button
          onClick={() => {
            shopify.modal.hide('publish-campaign-modal')
          }}
        >
          {t('genericCancel')}
        </button>
      </TitleBar>
    </Modal>
  )
}

export { CampaignPublishModal }
