import { ReactNode, useState } from 'react'
import { BlockStack, Card, Text, InlineStack, Button, Box, ButtonGroup } from '@shopify/polaris'
import { XIcon } from '@shopify/polaris-icons'

type ExtendedButton = {
  label?: string
  props: {
    url?: string
    external?: boolean
    onClick?: () => void
    disabled?: boolean
    loading?: boolean
  }
}

export type DismissableCardProps = {
  title: string
  description: string
  illustration?: ReactNode
  primaryCta?: ExtendedButton
  secondaryCta?: ExtendedButton
  isDismissDisabled?: boolean
  onDismiss?: () => void
  className?: string
}

export const DismissableCard = ({ title, description, illustration, primaryCta, secondaryCta, onDismiss, className }: DismissableCardProps) => {
  const [isDismissed, setIsDismissed] = useState(false)

  if (isDismissed) {
    return null
  }

  return (
    <Card padding="0">
      <div className={`thingsToDoCardWrapper ${className ?? ''}`}>
        <div className="overflowMenu">
          {onDismiss && (
            <Button
              variant="tertiary"
              icon={XIcon}
              onClick={() => {
                onDismiss()
                setIsDismissed(true)
              }}
            />
          )}
        </div>
        <div className="illustrationCard">
          <Box padding="400">
            <BlockStack gap="400">
              <BlockStack gap="200">
                {title && (
                  <Text as="h3" variant="headingMd">
                    <span>{title}</span>
                  </Text>
                )}
                {description && (
                  <Text as="p" variant="bodyMd">
                    {description}
                  </Text>
                )}
              </BlockStack>
              {primaryCta && (
                <InlineStack align="start">
                  <ButtonGroup>
                    <Button accessibilityLabel={primaryCta.label} {...primaryCta.props}>
                      {primaryCta.label}
                    </Button>
                    {secondaryCta && (
                      <Button variant="tertiary" accessibilityLabel={secondaryCta.label} {...secondaryCta.props}>
                        {secondaryCta.label}
                      </Button>
                    )}
                  </ButtonGroup>
                </InlineStack>
              )}
            </BlockStack>
          </Box>

          {illustration && <div className="illustration">{illustration}</div>}
        </div>
      </div>
    </Card>
  )
}
