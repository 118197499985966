import { useMutateCampaignSchedule } from '@/hooks/useMutateCampaignSchedule'
import { Modal, TitleBar, useAppBridge } from '@shopify/app-bridge-react'
import { Card, Banner, BlockStack, Box, Checkbox, Text } from '@shopify/polaris'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const CampaignPauseModal = ({ uuid }: { uuid: string }) => {
  const { t } = useTranslation()
  const shopify = useAppBridge()
  const [resetStock, setResetStock] = useState(true)

  const campaignScheduleMutation = useMutateCampaignSchedule(uuid)

  const handleConfirmPause = () => {
    shopify.modal.hide('pause-campaign-modal')
    campaignScheduleMutation.mutate({ action: 'UNPUBLISH', setStock: resetStock })
  }

  return (
    <Modal id="pause-campaign-modal">
      <Box padding="400">
        <div className="modalBannerCard">
          <Card padding="0">
            <BlockStack gap="400">
              <Banner tone="warning">
                <p>{t('campaignsManagePauseModalWarning')}</p>
              </Banner>
            </BlockStack>
          </Card>
        </div>
      </Box>
      <Box padding="400" paddingBlockStart="0">
        <BlockStack gap="400">
          <Text as="p">{t('campaignsManagePauseModalDescription')}</Text>
          <Checkbox label={t('campaignsManagePauseModalResetStockLabel')} checked={resetStock} onChange={() => setResetStock(!resetStock)} />
        </BlockStack>
      </Box>

      <TitleBar title={t('campaignsManagePauseModalTitle')}>
        <button variant="primary" onClick={handleConfirmPause}>
          {resetStock ? t('campaignsManagePauseModalSetStockCta') : t('campaignsManagePauseModalLeaveStockCta')}
        </button>
        <button
          onClick={() => {
            shopify.modal.hide('pause-campaign-modal')
          }}
        >
          {t('genericCancel')}
        </button>
      </TitleBar>
    </Modal>
  )
}

export { CampaignPauseModal }
