import { useFormContext } from 'react-hook-form'
import { useParams } from '@tanstack/react-router'

import { BlockStack, Card } from '@shopify/polaris'

import { FetchCampaignResponse, ShopifyProduct } from '@/common/types'

import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'
import { ResourcePicker } from '@/components/shared/ResourcePicker'
import { useQuery, useSuspenseQueries } from '@tanstack/react-query'
import { settingsQueryOptions, campaignUnavailableProductsQueryOptions } from '@/common/queryOptions'
import { Disabled } from '../shared/Disabled'

const ProductSelection = ({ status }: { status: FetchCampaignResponse['status'] | undefined }) => {
  const { t } = useTranslation()
  const { setValue, getFieldState, clearErrors, watch } = useFormContext()
  const { uuid } = useParams({ strict: false }) as { uuid: string }

  const campaignProducts = watch('products') as ShopifyProduct[]
  const campaignStartDate = watch('startDate')
  const campaignEndDate = watch('endDate')
  const isPublished = watch('isPublished')

  const [productIds, variantIds] = campaignProducts.reduce(
    (acc: [string[], string[]], product) => {
      if (!product.variants || product.variants.length === 0) {
        acc[0].push(product.id)
      } else {
        acc[1].push(...product.variants.map((variant) => variant.id))
      }

      return acc
    },
    [[], []] as [string[], string[]]
  )

  const [{ data: settings }] = useSuspenseQueries({
    queries: [settingsQueryOptions()],
  })

  const { data: unavailableProducts } = useQuery({
    ...campaignUnavailableProductsQueryOptions({
      uuid,
      from: campaignStartDate ?? null,
      to: campaignEndDate ?? null,
    }),
  })

  const invalidProductIds = unavailableProducts?.products
    ? unavailableProducts?.products.reduce((acc, product) => {
        if (productIds.includes(product.productId)) {
          acc.push(product.productId)
        }

        return acc
      }, [] as string[]) ?? []
    : []

  const invalidVariantIds = unavailableProducts?.variants
    ? unavailableProducts?.variants.reduce((acc, product) => {
        if (variantIds.includes(product.variantId)) {
          acc.push(product.variantId)
        }

        return acc
      }, [] as string[]) ?? []
    : []

  const onSelect = (selection: ShopifyProduct[]) => {
    setValue(
      'products',
      selection.map((product) => {
        const isAllVariants = product.variants?.length === 0 || product.variants?.length === product.totalVariants

        return {
          ...product,
          variants: isAllVariants ? [] : product.variants ?? [],
        }
      }),
      {
        shouldDirty: true,
      }
    )

    clearErrors('products')
  }

  const onRemove = (product: ShopifyProduct) => {
    const updatedProducts = campaignProducts.filter(({ id }) => id !== product.id)

    setValue('products', updatedProducts, {
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader title={t('campaignProductsCardTitle')} />
        <Disabled isDisabled={isPublished || status === 'SCHEDULED'} message={t('campaignProductsDisabledBanner')}>
          <ResourcePicker
            type="variant"
            initialProducts={campaignProducts}
            invalidProductIds={invalidProductIds}
            invalidVariantIds={invalidVariantIds}
            productLimit={settings.productLimit ?? 250}
            onSelect={onSelect}
            onRemove={onRemove}
            error={getFieldState('products').error}
          />
        </Disabled>
      </BlockStack>
    </Card>
  )
}

export { ProductSelection }
