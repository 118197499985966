import { Controller, useFormContext } from 'react-hook-form'
import { TextField, Card, InlineGrid, BlockStack, ChoiceList, Box } from '@shopify/polaris'
import { CardHeader } from '@/components/shared/CardHeader'
import { DNSSettings } from './DNSSettings'
import { EmailStatusBadge } from '@/components/shared/EmailStatusBadge'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { useSuspenseQuery } from '@tanstack/react-query'
import { Link, useNavigate } from '@tanstack/react-router'
import { settingsQueryOptions } from '@/common/queryOptions'
import { buildAdminUrl } from '@/common/functions'

const defaultEmail = import.meta.env.DEV ? 'noreply@ebd.shopside.com.au' : 'noreply@ebp1.shopside.com.au'

const EmailSettings = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { control, watch } = useFormContext()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const hasCustomEmailDomainFeature = settings.featureCustomEmailDomain === true

  const emailDomainStatus = watch('emailDomainStatus')
  const emailFromName = watch('emailFromName')
  const hasCustomDomain = !hasCustomEmailDomainFeature ? false : watch('hasCustomDomain')
  const showDnsSettings = hasCustomDomain === true && watch('emailDomainId') && hasCustomEmailDomainFeature
  const [isEmailLocked, setIsEmailLocked] = useState(emailDomainStatus === 'verified' || emailDomainStatus === 'pending')

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader title={t('settingsEmailSenderTitle')} description={t('settingsEmailSenderSubtitle')} />
        <InlineGrid columns="2" gap="400">
          <Controller
            name="emailFromName"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('settingsFieldFromName')}
                placeholder="Shopside"
                onChange={onChange}
                onBlur={onBlur}
                maxLength={100}
                value={value ?? ''}
                error={error?.message}
              />
            )}
          />
          <Controller
            name="emailReplyToEmail"
            control={control}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <TextField
                autoComplete="off"
                label={t('settingsFieldReplyToAddress')}
                placeholder={defaultEmail}
                onChange={onChange}
                onBlur={onBlur}
                value={value ?? ''}
                error={error?.message}
              />
            )}
          />
        </InlineGrid>
        <Disabled
          isDisabled={!hasCustomEmailDomainFeature}
          isLowerOpacity={false}
          tone="warning"
          message={
            <p>
              <Trans t={t} i18nKey="genericUpgradePlanBanner">
                .
                <Link
                  to={buildAdminUrl('plans')}
                  onClick={async (e) => {
                    e.preventDefault()

                    /* @ts-ignore */
                    await shopify.saveBar.leaveConfirmation()

                    navigate({ to: '/plans' })
                  }}
                >
                  .
                </Link>
                .
              </Trans>
            </p>
          }
        >
          <Controller
            name="hasCustomDomain"
            control={control}
            render={({ field: { onChange, value } }) => (
              <ChoiceList
                title={t('settingsFieldCustomDomain')}
                titleHidden
                choices={[
                  {
                    label: t('settingsFieldDefaultDomain'),
                    helpText: t('settingsEmailSenderVerifiedBanner', {
                      name: emailFromName ?? 'Shopside',
                      email: defaultEmail,
                    }),
                    value: 'false',
                  },
                  {
                    label: t('settingsFieldCustomDomain'),
                    helpText: t('settingsFieldCustomDomainHelp'),
                    value: 'true',
                    disabled: !hasCustomEmailDomainFeature,
                    renderChildren: (isSelected: boolean) => {
                      if (!isSelected) return false

                      return (
                        <Box paddingBlockStart="200">
                          <BlockStack gap="400">
                            <Controller
                              name="emailFromEmail"
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <TextField
                                  autoComplete="off"
                                  label={t('settingsFieldFromAddress')}
                                  placeholder={defaultEmail}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  value={value ?? ''}
                                  error={error?.message}
                                  readOnly={isEmailLocked}
                                  labelAction={isEmailLocked ? { content: t('genericChange'), onAction: () => setIsEmailLocked(!isEmailLocked) } : undefined}
                                  suffix={<EmailStatusBadge status={emailDomainStatus} />}
                                />
                              )}
                            />
                            {showDnsSettings && <DNSSettings emailDomainStatus={emailDomainStatus} />}
                          </BlockStack>
                        </Box>
                      )
                    },
                  },
                ]}
                selected={value.toString()}
                onChange={(values) => onChange(values[0] === 'true')}
              />
            )}
          />
        </Disabled>
      </BlockStack>
    </Card>
  )
}

export { EmailSettings }
