import { Controller, useFormContext } from 'react-hook-form'

import { BlockStack, Card, Tabs, Box, TextField, Layout, Grid, Checkbox } from '@shopify/polaris'

import { useCallback, useEffect, useState, useTransition } from 'react'
import { EmailPreview } from '@/components/shared/EmailPreview'
import { replaceInTemplate, openBeacon } from '@/common/functions'
import { Trans, useTranslation } from 'react-i18next'
import { Disabled } from '@/components/shared/Disabled'
import { useSuspenseQuery } from '@tanstack/react-query'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { settingsQueryOptions } from '@/common/queryOptions'

const BackInStock = ({ defaultTemplateHtml, isDisabled }: { defaultTemplateHtml: string; isDisabled: boolean }) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const [_, startTransition] = useTransition()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const hasCustomEmailTemplatesFeature = settings.featureCustomEmailTemplates

  const TABS = [
    { id: '0', content: t('emailTemplatesTabEdit') },
    { id: '1', content: t('emailTemplatesTabTemplate') },
  ]

  const [selected, setSelected] = useState(0)

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    startTransition(() => {
      setSelected(selectedTabIndex)
    })
  }, [])

  const editorMarkup = () => {
    if (selected === 0) {
      return <Editor />
    }
    if (selected === 1) {
      return <Template isDisabled={!hasCustomEmailTemplatesFeature} defaultTemplateHtml={defaultTemplateHtml} />
    }
  }

  const templateHtml = watch('backInStock.hasCustomTemplate') && hasCustomEmailTemplatesFeature ? watch('backInStock.templateHtml') : defaultTemplateHtml
  const body = watch('backInStock.body')
  const title = watch('backInStock.title')
  const primaryButtonText = watch('backInStock.primaryButtonText')
  const subject = watch('backInStock.subject')

  return (
    <Layout.Section>
      <SectionTitle title={t('emailTemplatesBackInStockTitle')} description={t('emailTemplatesBackInStockDescription')} />
      <Grid columns={{ sm: 6, md: 6, lg: 12 }}>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 7, xl: 7 }}>
          <Card padding="0">
            <Tabs selected={selected} onSelect={handleTabChange} fitted tabs={TABS}>
              <Box padding="400" paddingBlockStart="200">
                <Disabled isDisabled={isDisabled}>{editorMarkup()}</Disabled>
              </Box>
            </Tabs>
          </Card>
        </Grid.Cell>
        <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 3, lg: 5, xl: 5 }}>
          <Card>
            <BackInStockPreview
              templateHtml={templateHtml}
              shopName={settings.shopName}
              body={body}
              title={title}
              primaryButtonText={primaryButtonText}
              subject={subject}
            />
          </Card>
        </Grid.Cell>
      </Grid>
    </Layout.Section>
  )
}

export const BackInStockPreview = ({
  templateHtml,
  shopName,
  body,
  subject,
  title,
  primaryButtonText,
  productName,
  productImage,
  productImageAlt,
}: {
  templateHtml: string | null
  shopName: string
  body: string | null
  subject?: string
  title: string | null
  primaryButtonText: string | null
  productName?: string
  productImage?: string
  productImageAlt?: string
}) => {
  let parsedBody = body ? replaceInTemplate(body, [{ find: '{{PRODUCT_NAME}}', replace: productName ?? 'Example Product' }]) : ''

  parsedBody = parsedBody
    // Remove leading and trailing whitespace
    .trim()
    // Remove unwanted whitespace between paragraphs
    .replace(/\n\s*\n/g, '\n\n')
    // Split by double newlines to identify paragraphs
    .split(/\n\n/)
    .map((paragraph) => {
      // Check if the paragraph contains HTML tags
      if (!/<(?:.|\n)*?>/.test(paragraph)) {
        // If it doesn't contain HTML tags, wrap it in <p> tags
        return `<p>${paragraph.trim()}</p>`
      }
      // If it contains HTML tags, leave it unchanged
      return paragraph
    })
    .join('')

  let parsedHtml = replaceInTemplate(templateHtml ?? '', [
    { find: '{{SHOP_NAME}}', replace: shopName ?? '' },
    { find: '{{TITLE}}', replace: title ?? '' },
    { find: '{{BODY}}', replace: parsedBody },
    { find: '{{BUTTON_TEXT}}', replace: primaryButtonText ?? '' },
    { find: '{{BUTTON_URL}}', replace: 'javascript:;' },
    { find: '{{PRODUCT_IMAGE}}', replace: productImage ?? '/placeholder-product.webp' },
    { find: '{{PRODUCT_IMAGE_ALT}}', replace: productImageAlt ?? 'Placeholder image' },
  ])

  return <EmailPreview subject={subject} htmlContent={parsedHtml} />
}

const Editor = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <BlockStack gap="200">
      <Controller
        name="backInStock.subject"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('emailTemplatesFieldSubject')}
            maxLength={256}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            error={error?.message}
          />
        )}
      />
      <Controller
        name="backInStock.title"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField autoComplete="off" label={t('emailTemplatesFieldTitle')} onChange={onChange} onBlur={onBlur} value={value} error={error?.message} />
        )}
      />
      <BlockStack gap="200">
        <Controller
          name="backInStock.body"
          control={control}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <TextField
              id="backInStock.body"
              autoComplete="off"
              label={t('emailTemplatesFieldBody')}
              helpText={
                <Trans t={t} i18nKey="emailTemplatesFieldBodyHelpText">
                  .
                  <a target="_blank" href="https://www.shopside.com.au/earlybird-article/dynamic-email-template-tags">
                    .
                  </a>
                  .
                </Trans>
              }
              multiline={5}
              maxHeight={300}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              error={error?.message}
            />
          )}
        />
      </BlockStack>
      <Controller
        name="backInStock.primaryButtonText"
        control={control}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <TextField
            autoComplete="off"
            label={t('emailTemplatesFieldPrimaryCta')}
            helpText={t('emailTemplatesFieldPrimaryCtaHelpText')}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={100}
            value={value}
            error={error?.message}
          />
        )}
      />
    </BlockStack>
  )
}

const Template = ({ isDisabled, defaultTemplateHtml }: { isDisabled: boolean; defaultTemplateHtml: string }) => {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()

  const hasCustomTemplate = watch('backInStock.hasCustomTemplate')
  const templateHtml = watch('backInStock.templateHtml')

  useEffect(() => {
    if (hasCustomTemplate && !templateHtml) {
      setValue('backInStock.templateHtml', defaultTemplateHtml)
    }
  }, [hasCustomTemplate])

  return (
    <>
      <Disabled
        isDisabled={isDisabled}
        isLowerOpacity={true}
        message={
          <p>
            <Trans t={t} i18nKey="genericContactUsBanner">
              .
              <a
                href="#"
                onClick={async () => {
                  openBeacon('ASK')
                }}
              >
                .
              </a>
              .
            </Trans>
          </p>
        }
      >
        <BlockStack gap="200">
          <Controller
            name="backInStock.hasCustomTemplate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                label={t('emailTemplatesFieldHasCustomTemplate')}
                helpText={t('emailTemplatesFieldHasCustomTemplateHelp')}
                checked={hasCustomTemplate && value ? value : false}
                onChange={onChange}
              />
            )}
          />
          {hasCustomTemplate && (
            <>
              {isDisabled ? (
                <TextField autoComplete="off" label={t('emailTemplatesTabTemplate')} multiline={14} maxHeight={300} placeholder={defaultTemplateHtml} />
              ) : (
                <Controller
                  name="backInStock.templateHtml"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      autoComplete="off"
                      label={t('emailTemplatesTabTemplate')}
                      multiline={14}
                      maxHeight={300}
                      onChange={onChange}
                      value={value}
                      placeholder={defaultTemplateHtml}
                      error={error?.message}
                      readOnly={!hasCustomTemplate}
                    />
                  )}
                />
              )}
            </>
          )}
        </BlockStack>
      </Disabled>
    </>
  )
}

export { BackInStock }
