import { useNavigate, createFileRoute } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { bisSettingQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { BackInStockSettingsForm } from '@/components/backInStock/BackInStockSettingsForm'
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Text } from '@shopify/polaris'
import { BackInStockStatusBadge } from '@/components/shared/BackInStockStatusBadge'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/back-in-stock/settings')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      const settings = await queryClient.ensureQueryData(settingsQueryOptions())
      queryClient.ensureQueryData(bisSettingQueryOptions({ uuid: settings.bisSettingsId }))
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  return (
    <PageFrame
      title={t('backInStockSettingsTitle')}
      subtitle={t('backInStockSettingsTitleSubtitle')}
      titleMetadata={<BackInStockStatusBadge enabled={settings.bisEnabled} />}
      backAction={{
        content: t('backInStockDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/back-in-stock` })
        },
      }}
    >
      <BackInStockSettingsForm />
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('backInStockSettingsTitle')}>
      <Layout>
        <Layout.AnnotatedSection title={t('backInStockSelectionTypeTitle')} description={t('backInStockSelectionTypeDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('backInStockProductsTitle')} description={t('backInStockProductsExcludedDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('backInStockSendTitle')} description={t('backInStockSendDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={t('backInStockDeliveryTitle')}
          description={
            <>
              <Text as="p" variant="bodyMd">
                {t('backInStockDeliveryDescription')}
              </Text>
              <br />
              <Text as="p" variant="bodyMd">
                {t('backInStockDeliveryBatchDescription')}
              </Text>
            </>
          }
        >
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
