import { Badge } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import type { EmailEventType } from '@/common/types'

const EmailEventStatusBadge = ({ status }: { status: EmailEventType }) => {
  const { t } = useTranslation()

  let label: string
  let tone: 'info' | 'success' | 'warning' | 'attention' | undefined

  switch (status) {
    case 'email.sent':
      label = t('backInStockEmailEventsTableTypeSent')
      tone = undefined
      break
    case 'email.delivered':
      label = t('backInStockEmailEventsTableTypeDelivered')
      tone = 'info'
      break
    case 'email.delivery_delayed':
      label = t('backInStockEmailEventsTableTypeDeliveryDelayed')
      tone = 'warning'
      break
    case 'email.complained':
      label = t('backInStockEmailEventsTableTypeComplained')
      tone = 'attention'
      break
    case 'email.bounced':
      label = t('backInStockEmailEventsTableTypeBounced')
      tone = 'warning'
      break
    case 'email.opened':
      label = t('backInStockEmailEventsTableTypeOpened')
      tone = 'success'
      break
    case 'email.clicked':
      label = t('backInStockEmailEventsTableTypeClicked')
      tone = 'success'
      break
    default:
      label = t('backInStockContactTableStatusNotSent')
      tone = undefined
      break
  }

  return label ? <Badge tone={tone}>{label}</Badge> : null
}

export { EmailEventStatusBadge }
