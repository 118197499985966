import { Controller, useFormContext } from 'react-hook-form'

import { Card, BlockStack, Checkbox, TextField, Select, Box, InlineGrid, InlineError } from '@shopify/polaris'

import { Trans, useTranslation } from 'react-i18next'

import { DatePickerField } from '@/components/shared/DatePickerField'
import { ErrorMessage } from '@hookform/error-message'
import { CardHeader } from '@/components/shared/CardHeader'
import { Disabled } from '@/components/shared/Disabled'

import dayjs from '@/common/datetime'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { settingsQueryOptions } from '@/common/queryOptions'
import { TimeSelectField } from '../shared/TimeSelectField'
import { useEffect } from 'react'
import { buildAdminUrl } from '@/common/functions'

const CampaignPayment = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    control,
    watch,
    trigger,
    formState: { errors, isDirty },
  } = useFormContext()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const hasFullSellingPlan = watch('sellingPlans.full.isActive')
  const hasPartialSellingPlan = watch('sellingPlans.partial.isActive')
  const fullHasDiscount = watch('sellingPlans.full.hasDiscount') ?? false
  const partialHasDiscount = watch('sellingPlans.partial.hasDiscount') ?? false

  const fullDiscountType = watch('sellingPlans.full.discountType', 'PERCENTAGE')
  const partialDiscountType = watch('sellingPlans.partial.discountType', 'PERCENTAGE')
  const partialDepositType = watch('sellingPlans.partial.partialType', 'PERCENTAGE')
  const partialFinalPaymentTrigger = watch('sellingPlans.partial.finalPaymentTrigger', 'TIME_AFTER_CHECKOUT')

  const hasPartialPaymentsFeature = settings.featurePartialPayments === true

  useEffect(() => {
    trigger('sellingPlans.full.discountAmount')
  }, [fullDiscountType])

  useEffect(() => {
    trigger('sellingPlans.partial.discountAmount')
  }, [partialDiscountType])

  useEffect(() => {
    if (!isDirty) return
    trigger('sellingPlans.root')
  }, [hasFullSellingPlan, hasPartialSellingPlan])

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader title={t('campaignPurchaseOptionsCardTitle')} description={t('campaignPurchaseOptionsCardDescription')} />
        <BlockStack gap="200">
          <ErrorMessage
            errors={errors}
            name="sellingPlans.root"
            render={({ message }) => (
              <div id="sellingPlans:Error">
                <InlineError message={message} fieldID="sellingPlans:Error" />
              </div>
            )}
          />
          <BlockStack gap="200">
            <Controller
              name="sellingPlans.full.isActive"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Checkbox
                  label={t('campaignFieldHasFullPlan')}
                  helpText={t('campaignFieldHasFullPlanDescription')}
                  checked={value ?? false}
                  onChange={onChange}
                  error={!!errors.sellingPlans?.root}
                />
              )}
            />
            {hasFullSellingPlan === true && (
              <Box background="bg-surface-secondary" padding="300" borderRadius="200">
                <BlockStack gap="400">
                  <Controller
                    name="sellingPlans.full.name"
                    control={control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        autoComplete="off"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value ?? ''}
                        maxLength={256}
                        label={t('campaignFieldFullPlan')}
                        placeholder={t('campaignFieldFullPlanPlaceholder')}
                        helpText={t('campaignFieldFullPlanDescription')}
                        error={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="sellingPlans.full.hasDiscount"
                    control={control}
                    render={({ field: { onChange, value } }) => <Checkbox label={t('campaignFieldHasDiscount')} checked={value ?? false} onChange={onChange} />}
                  />
                  {fullHasDiscount === true && (
                    <InlineGrid columns="2" gap="400">
                      <Controller
                        name="sellingPlans.full.discountType"
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            label={t('campaignFieldDiscountType')}
                            options={[
                              {
                                label: t('campaignFieldDiscountTypePercentage'),
                                value: 'PERCENTAGE',
                              },
                              {
                                label: t('campaignFieldDiscountTypeFixed'),
                                value: 'FIXED',
                              },
                            ]}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />

                      <Controller
                        name="sellingPlans.full.discountAmount"
                        control={control}
                        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                          return (
                            <TextField
                              autoComplete="off"
                              label={t('campaignFieldDiscountAmount')}
                              onBlur={onBlur}
                              value={value?.toString() ?? ''}
                              onChange={(val) => {
                                let parsed = parseFloat(val)
                                if (!isNaN(parsed)) {
                                  parsed = parseFloat(parsed.toFixed(2))
                                }
                                onChange(isNaN(parsed) ? null : parsed)
                              }}
                              type="number"
                              min={0}
                              max={2147483647}
                              suffix={fullDiscountType === 'PERCENTAGE' ? '%' : settings.currency}
                              error={error?.message}
                            />
                          )
                        }}
                      />
                    </InlineGrid>
                  )}
                </BlockStack>
              </Box>
            )}
            <Disabled
              isDisabled={!hasPartialPaymentsFeature}
              tone="warning"
              message={
                <p>
                  <Trans t={t} i18nKey="genericUpgradePlanBanner">
                    .
                    <a
                      href={buildAdminUrl('plans')}
                      onClick={async (e) => {
                        e.preventDefault()
                        /* @ts-ignore */
                        await shopify.saveBar.leaveConfirmation()
                        navigate({ to: '/plans' })
                      }}
                    >
                      .
                    </a>
                    .
                  </Trans>
                </p>
              }
            >
              <BlockStack gap="400">
                {!hasPartialPaymentsFeature ? (
                  <Checkbox label={t('campaignFieldHasPartialPlan')} helpText={t('campaignFieldHasPartialPlanDescription')} />
                ) : (
                  <Controller
                    name="sellingPlans.partial.isActive"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label={t('campaignFieldHasPartialPlan')}
                        helpText={t('campaignFieldHasPartialPlanDescription')}
                        checked={value ?? false}
                        onChange={onChange}
                        error={!!errors.sellingPlans?.root}
                      />
                    )}
                  />
                )}
                {(hasPartialSellingPlan === true || !hasPartialPaymentsFeature) && (
                  <Box background="bg-surface-secondary" padding="300" borderRadius="200">
                    <BlockStack gap="400">
                      {!hasPartialPaymentsFeature ? (
                        <TextField
                          autoComplete="off"
                          label={t('campaignFieldPartialPlanName')}
                          placeholder={t('campaignFieldPartialPlanPlaceholder')}
                          helpText={t('campaignFieldPartialPlanNameDescription')}
                          value={''}
                        />
                      ) : (
                        <Controller
                          name="sellingPlans.partial.name"
                          control={control}
                          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                            <TextField
                              autoComplete="off"
                              label={t('campaignFieldPartialPlanName')}
                              placeholder={t('campaignFieldPartialPlanPlaceholder')}
                              helpText={t('campaignFieldPartialPlanNameDescription')}
                              onChange={onChange}
                              onBlur={onBlur}
                              maxLength={256}
                              value={value}
                              error={error?.message}
                            />
                          )}
                        />
                      )}

                      <InlineGrid columns="2" gap="400">
                        {!hasPartialPaymentsFeature ? (
                          <Select
                            label={t('campaignFieldPartialPlanType')}
                            options={[
                              {
                                label: t('campaignFieldPartialPlanTypePercentage'),
                                value: 'PERCENTAGE',
                              },
                              {
                                label: t('campaignFieldPartialPlanTypeFixed'),
                                value: 'FIXED',
                              },
                            ]}
                            onChange={() => {}}
                          />
                        ) : (
                          <Controller
                            name="sellingPlans.partial.partialType"
                            control={control}
                            render={({ field: { onChange, onBlur, value } }) => (
                              <Select
                                label={t('campaignFieldPartialPlanType')}
                                options={[
                                  {
                                    label: t('campaignFieldPartialPlanTypePercentage'),
                                    value: 'PERCENTAGE',
                                  },
                                  {
                                    label: t('campaignFieldPartialPlanTypeFixed'),
                                    value: 'FIXED',
                                  },
                                ]}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                              />
                            )}
                          />
                        )}
                        {!hasPartialPaymentsFeature ? (
                          <TextField
                            autoComplete="off"
                            label={t('campaignFieldPartialPlanAmount')}
                            suffix={partialDepositType === 'PERCENTAGE' ? '%' : settings.currency}
                            value={watch('sellingPlans.partial.depositAmount')}
                          />
                        ) : (
                          <Controller
                            name="sellingPlans.partial.partialAmount"
                            control={control}
                            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                              return (
                                <TextField
                                  autoComplete="off"
                                  label={t('campaignFieldPartialPlanAmount')}
                                  value={value?.toString() ?? undefined}
                                  onChange={(val) => {
                                    let parsed = parseFloat(val)
                                    if (!isNaN(parsed)) {
                                      parsed = parseFloat(parsed.toFixed(2))
                                    }
                                    onChange(isNaN(parsed) ? null : parsed)
                                  }}
                                  type="number"
                                  min={0}
                                  max={2147483647}
                                  onBlur={onBlur}
                                  suffix={partialDepositType === 'PERCENTAGE' ? '%' : settings.currency}
                                  error={error?.message}
                                />
                              )
                            }}
                          />
                        )}
                      </InlineGrid>

                      {!hasPartialPaymentsFeature ? (
                        <Select
                          label={t('campaignFieldPartialPlanFinalPaymentTrigger')}
                          options={[
                            {
                              label: t('campaignFieldPartialPlanTimeAfterCheckout'),
                              value: 'TIME_AFTER_CHECKOUT',
                            },
                            {
                              label: t('campaignFieldPartialPlanExactTime'),
                              value: 'EXACT_TIME',
                            },
                          ]}
                          onChange={() => {}}
                        />
                      ) : (
                        <Controller
                          name="sellingPlans.partial.finalPaymentTrigger"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                              label={t('campaignFieldPartialPlanFinalPaymentTrigger')}
                              options={[
                                {
                                  label: t('campaignFieldPartialPlanTimeAfterCheckout'),
                                  value: 'TIME_AFTER_CHECKOUT',
                                },
                                {
                                  label: t('campaignFieldPartialPlanExactTime'),
                                  value: 'EXACT_TIME',
                                },
                              ]}
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                          )}
                        />
                      )}

                      {partialFinalPaymentTrigger === 'TIME_AFTER_CHECKOUT' &&
                        (!hasPartialPaymentsFeature ? (
                          <InlineGrid columns={2} gap="400">
                            <TextField autoComplete="off" label={t('campaignFieldPartialPlanNumberOfDays')} />
                          </InlineGrid>
                        ) : (
                          <InlineGrid columns={2} gap="400">
                            <Controller
                              key="sellingPlans.partial.numberOfDays"
                              name="sellingPlans.partial.numberOfDays"
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                                <TextField
                                  autoComplete="off"
                                  label={t('campaignFieldPartialPlanNumberOfDays')}
                                  value={value?.toString() ?? undefined}
                                  onChange={(val) => {
                                    const parsed = parseInt(val, 10)
                                    onChange(isNaN(parsed) ? null : parsed)
                                  }}
                                  type="number"
                                  min={0}
                                  max={2147483647}
                                  onBlur={onBlur}
                                  error={error?.message}
                                />
                              )}
                            />
                          </InlineGrid>
                        ))}

                      {partialFinalPaymentTrigger === 'EXACT_TIME' &&
                        (!hasPartialPaymentsFeature ? (
                          <InlineGrid columns={2} gap="400">
                            <DatePickerField
                              label={t('campaignFieldPartialPlanPaymentDate')}
                              displayTimezone={settings.timezone}
                              onChange={() => {}}
                              date={dayjs().toISOString()}
                            />
                            <TimeSelectField
                              label={t('campaignFieldPartialPlanPaymentTime')}
                              displayTimezone={settings.timezone}
                              onChange={() => {}}
                              date={dayjs().toISOString()}
                            />
                          </InlineGrid>
                        ) : (
                          <Controller
                            name="sellingPlans.partial.paymentDueDate"
                            control={control}
                            render={({ field, fieldState: { error }, formState: {} }) => {
                              return (
                                <>
                                  <InlineGrid columns={2} gap="400">
                                    <DatePickerField
                                      label={t('campaignFieldPartialPlanPaymentDate')}
                                      date={field.value}
                                      displayTimezone={settings.timezone}
                                      onChange={(val) => field.onChange(val)}
                                      error={error}
                                    />
                                    <TimeSelectField
                                      label={t('campaignFieldPartialPlanPaymentTime')}
                                      date={field.value}
                                      displayTimezone={settings.timezone}
                                      onChange={(val) => field.onChange(val)}
                                      disabled={!field.value}
                                    />
                                  </InlineGrid>
                                </>
                              )
                            }}
                          />
                        ))}

                      {!hasPartialPaymentsFeature ? (
                        <Checkbox label={t('campaignFieldHasDiscount')} />
                      ) : (
                        <Controller
                          name="sellingPlans.partial.hasDiscount"
                          control={control}
                          render={({ field: { onChange, value } }) => <Checkbox label={t('campaignFieldHasDiscount')} checked={value} onChange={onChange} />}
                        />
                      )}
                      {partialHasDiscount === true && (
                        <InlineGrid columns="2" gap="400">
                          {!hasPartialPaymentsFeature ? (
                            <Select
                              label={t('campaignFieldDiscountType')}
                              options={[
                                {
                                  label: t('campaignFieldDiscountTypePercentage'),
                                  value: 'PERCENTAGE',
                                },
                                {
                                  label: t('campaignFieldDiscountTypeFixed'),
                                  value: 'FIXED',
                                },
                              ]}
                              onChange={() => {}}
                            />
                          ) : (
                            <Controller
                              name="sellingPlans.partial.discountType"
                              control={control}
                              render={({ field: { onChange, onBlur, value } }) => (
                                <Select
                                  label={t('campaignFieldDiscountType')}
                                  options={[
                                    {
                                      label: t('campaignFieldDiscountTypePercentage'),
                                      value: 'PERCENTAGE',
                                    },
                                    {
                                      label: t('campaignFieldDiscountTypeFixed'),
                                      value: 'FIXED',
                                    },
                                  ]}
                                  value={value}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                />
                              )}
                            />
                          )}
                          {!hasPartialPaymentsFeature ? (
                            <TextField
                              autoComplete="off"
                              label={t('campaignFieldDiscountAmount')}
                              suffix={partialDiscountType === 'PERCENTAGE' ? '%' : settings.currency}
                            />
                          ) : (
                            <Controller
                              name="sellingPlans.partial.discountAmount"
                              control={control}
                              render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                                return (
                                  <TextField
                                    autoComplete="off"
                                    label={t('campaignFieldDiscountAmount')}
                                    value={value?.toString() ?? undefined}
                                    onChange={(val) => {
                                      let parsed = parseFloat(val)
                                      if (!isNaN(parsed)) {
                                        parsed = parseFloat(parsed.toFixed(2))
                                      }
                                      onChange(isNaN(parsed) ? null : parsed)
                                    }}
                                    type="number"
                                    min={0}
                                    max={2147483647}
                                    onBlur={onBlur}
                                    suffix={partialDiscountType === 'PERCENTAGE' ? '%' : settings.currency}
                                    error={error?.message}
                                  />
                                )
                              }}
                            />
                          )}
                        </InlineGrid>
                      )}
                    </BlockStack>
                  </Box>
                )}
              </BlockStack>
            </Disabled>
          </BlockStack>
        </BlockStack>
      </BlockStack>
    </Card>
  )
}

export { CampaignPayment }
