// External Imports
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from '@tanstack/react-router'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

// Shopify Polaris and App Bridge Imports
import { Banner, BlockStack, Layout } from '@shopify/polaris'
import { SaveBar } from '@shopify/app-bridge-react'

// Custom Types and Utility Imports
import { FetchCampaignResponse } from '@/common/types'
import { campaignQueryOptions } from '@/common/queryOptions'

// Custom Hooks
import useScrollToError from '@/hooks/useScrollToError'
import useCampaignForm from '@/hooks/useCampaignForm'

// Component Imports
import { CampaignName } from '@/components/preorders/CampaignName'
import { ProductSelection } from '@/components/preorders/CampaignProducts'
import { CampaignDuration } from '@/components/preorders/CampaignDuration'
import { CampaignFulfillment } from '@/components/preorders/CampaignFulfillment'
import { CampaignPayment } from '@/components/preorders/CampaignPayment'
import { CampaignTrigger } from '@/components/preorders/CampaignTrigger'
import { CampaignInventoryPolicy } from '@/components/preorders/CampaignInventoryPolicy'
import { CampaignStockLimit } from '@/components/preorders/CampaignStockLimit'
import { FormSchema } from '@/components/preorders/CampaignForm/schema'
import { Disabled } from '@/components/shared/Disabled'
import { openBeacon } from '@/common/functions'

// Constants
const DISABLED_STATUSES = ['PROCESSING', 'PROCESSING_PRODUCTS', 'PROCESSING_CAMPAIGN', 'ARCHIVED']

// Main CampaignForm Component
const CampaignForm = ({ campaign }: { campaign?: FetchCampaignResponse }) => {
  // Hooks
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { form, onSubmit, mutation, errorMessage, setErrorMessage } = useCampaignForm(campaign)

  // Flags
  const isEditing = !!campaign
  const isDisabled = ((campaign && DISABLED_STATUSES.includes(campaign.status)) || mutation.isPending) ?? false

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = form

  // Handle errors and focus
  const [setCanFocus] = useScrollToError(form.formState.errors)

  const watchPreorderTrigger = watch('preorderTrigger')

  // Show/hide the save bar based on form dirty state
  useEffect(() => {
    if (isDisabled) return

    if (isDirty) {
      shopify.saveBar.show('save-bar')
    } else {
      shopify.saveBar.hide('save-bar')
    }
  }, [isDirty, isDisabled])

  // Handle form submission
  const handleOnSubmit = async (data: FormSchema) => {
    setErrorMessage(null)

    try {
      const result = await onSubmit(data)

      shopify.saveBar.hide('save-bar')

      if (!isEditing) {
        shopify.toast.show(t('campaignsCreatedToast'), { duration: 2000 })
        queryClient.invalidateQueries({ queryKey: campaignQueryOptions().queryKey, exact: false, type: 'all' })
      } else {
        shopify.toast.show(t('campaignsUpdatedToast'), { duration: 2000 })
      }

      // Invalidate and refetch queries
      queryClient.invalidateQueries({ queryKey: campaignQueryOptions({ uuid: campaign?.uuid }).queryKey, exact: true, type: 'all' })
      navigate({ to: `/preorders/$uuid`, params: { uuid: result.uuid } })
    } catch (error) {
      // Focus on errors
      setCanFocus(true)
    }

    return true
  }

  // Handle form errors
  const handleOnError = () => {
    setCanFocus(true)
  }

  return (
    <FormProvider {...form}>
      <SaveBar id="save-bar">
        <button
          variant="primary"
          onClick={() => handleSubmit(handleOnSubmit, handleOnError)()}
          disabled={mutation.isPending}
          loading={mutation.isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={mutation.isPending}></button>
      </SaveBar>

      {errorMessage && (
        <Layout.Section>
          <Banner
            tone="critical"
            title={t('campaignSubmissionErrorTitle')}
            action={{
              content: t('genericContactSupport'),
              onAction: async () => {
                openBeacon('ASK')
              },
            }}
            onDismiss={() => {
              setErrorMessage(null)
            }}
          >
            {errorMessage}
          </Banner>
        </Layout.Section>
      )}

      <Layout.AnnotatedSection title={t('campaignsDetailsTitle')} description={t('campaignsDetailsDescription')}>
        <Disabled isDisabled={isDisabled}>
          <CampaignName />
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection title={t('campaignsAvailabilityTitle')} description={t('campaignsAvailabilityDescription')}>
        <Disabled isDisabled={isDisabled}>
          <BlockStack gap="400">
            <CampaignTrigger />
            {watchPreorderTrigger === 'IN_STOCK' && <CampaignStockLimit status={campaign?.status} />}
            <CampaignInventoryPolicy />
          </BlockStack>
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection title={t('campaignDurationTitle')} description={t('campaignDurationDescription')}>
        <Disabled isDisabled={isDisabled}>
          <CampaignDuration status={campaign?.status} />
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection title={t('campaignProductsTitle')} description={t('campaignProductsDescription')}>
        <Disabled isDisabled={isDisabled}>
          <ProductSelection status={campaign?.status} />
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection title={t('campaignPurchaseOptionsTitle')} description={t('campaignPurchaseOptionsDescription')}>
        <Disabled isDisabled={isDisabled}>
          <CampaignPayment />
        </Disabled>
      </Layout.AnnotatedSection>

      <Layout.AnnotatedSection title={t('campaignFulfillmentTitle')} description={t('campaignFulfillmentDescription')}>
        <Disabled isDisabled={isDisabled}>
          <CampaignFulfillment />
        </Disabled>
      </Layout.AnnotatedSection>
    </FormProvider>
  )
}

export { CampaignForm }
