import { FinancialStatus } from '@/common/types'
import { Badge, BadgeProgressValue } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const PaymentBadge = ({ financialStatus }: { financialStatus: FinancialStatus }) => {
  const { t } = useTranslation()

  let progress: BadgeProgressValue
  let label: string
  let tone: 'warning' | 'attention' | undefined

  switch (financialStatus) {
    case 'PAID':
      progress = BadgeProgressValue.Complete
      label = t('ordersTablePaymentStatusPaid')
      tone = undefined
      break
    case 'PARTIALLY_PAID':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('ordersTablePaymentStatusPartiallyPaid')
      tone = 'warning'
      break
    case 'PARTIALLY_REFUNDED':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('ordersTablePaymentStatusPartiallyRefunded')
      tone = undefined
      break
    case 'REFUNDED':
      progress = BadgeProgressValue.Complete
      label = t('ordersTablePaymentStatusRefunded')
      tone = undefined
      break
    case 'VOIDED':
      progress = BadgeProgressValue.Complete
      label = t('ordersTablePaymentStatusVoided')
      tone = undefined
      break
    case 'AUTHORIZED':
      progress = BadgeProgressValue.PartiallyComplete
      label = t('ordersTablePaymentStatusAuthorized')
      tone = 'attention'
      break
    case 'EXPIRED':
      progress = BadgeProgressValue.Complete
      label = t('ordersTablePaymentStatusExpired')
      tone = undefined
      break
    case 'PENDING':
      progress = BadgeProgressValue.Incomplete
      label = t('ordersTablePaymentStatusPending')
      tone = 'warning'
      break
  }

  return label ? (
    <Badge progress={progress} tone={tone}>
      {label}
    </Badge>
  ) : null
}

export { PaymentBadge }
