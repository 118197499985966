import { useFormContext } from 'react-hook-form'

import { BlockStack, Card } from '@shopify/polaris'

import { FetchSettingsResponse, ShopifyProduct } from '@/common/types'

import { CardHeader } from '@/components/shared/CardHeader'
import { useTranslation } from 'react-i18next'
import { ResourcePicker } from '@/components/shared/ResourcePicker'

/**
 * This component prepares the backorder products for the generic resource picker.
 * @param initialProducts
 * @returns ReactNode
 */
const ProductSelection = ({ selectionType }: { selectionType: FetchSettingsResponse['backorderSelectionType'] }) => {
  const { t } = useTranslation()
  const { setValue, clearErrors, watch } = useFormContext()

  const backorderProducts = watch('backorderProducts') as ShopifyProduct[]

  const onSelect = (selection: ShopifyProduct[]) => {
    setValue('backorderProducts', selection, {
      shouldDirty: true,
    })

    clearErrors('backorderProducts')
  }

  const onRemove = (product: ShopifyProduct) => {
    const updatedProducts = backorderProducts.filter(({ id }) => id !== product.id)

    setValue('backorderProducts', updatedProducts, {
      shouldDirty: true,
    })
  }

  return (
    <Card>
      <BlockStack gap="400">
        <CardHeader
          title={selectionType === 'EXCLUDE' ? t('backorderProductsExcludedCardTitle') : t('backorderProductsIncludedCardTitle')}
          description={selectionType === 'EXCLUDE' ? t('backorderProductsExcludedCardDescription') : t('backorderProductsIncludedCardDescription')}
        />
        <ResourcePicker type="product" initialProducts={backorderProducts} onSelect={onSelect} onRemove={onRemove} />
      </BlockStack>
    </Card>
  )
}

export { ProductSelection }
