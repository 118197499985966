// External libraries
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

// Internal components
import { CampaignStatusBadge } from '@/components/shared/CampaignStatusBadge'
import { PageFrame } from '@/components/shared/PageFrame'
import { CampaignForm } from '@/components/preorders/CampaignForm'

// Utility functions and configurations
import { campaignQueryOptions, settingsQueryOptions, campaignUnavailableProductsQueryOptions } from '@/common/queryOptions'
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'

export const Route = createFileRoute('/preorders/$uuid/settings')({
  loader: async ({ params: { uuid }, context: { queryClient }, route: { path } }) => {
    try {
      const settingsPromise = queryClient.ensureQueryData(settingsQueryOptions())
      const campaignPromise = queryClient.ensureQueryData(campaignQueryOptions({ uuid }))

      const [
        _,
        {
          data: [campaign],
        },
      ] = await Promise.all([settingsPromise, campaignPromise])

      queryClient.ensureQueryData(campaignUnavailableProductsQueryOptions({ uuid: uuid, from: campaign.startDate ?? null, to: campaign.endDate ?? undefined }))
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { uuid } = useParams({ strict: false }) as { uuid: string }

  const {
    data: {
      data: [campaign],
    },
  } = useSuspenseQuery(campaignQueryOptions({ uuid }))

  const title = campaign.name ?? t('campaignsEditTitle')

  return (
    <PageFrame
      title={title}
      subtitle={t('campaignsDashSubtitle')}
      titleMetadata={<CampaignStatusBadge status={campaign.status} withProgress={false} />}
      backAction={{
        content: t('campaignsManageTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: `/preorders/$uuid`, params: { uuid } })
        },
      }}
    >
      <CampaignForm campaign={campaign} />
    </PageFrame>
  )
}

function Pending() {
  const { t } = useTranslation()

  return (
    <SkeletonPage title={t('genericLoading')} backAction>
      <Layout>
        <Layout.AnnotatedSection title={t('campaignsDetailsTitle')} description={t('campaignsDetailsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignsAvailabilityTitle')} description={t('campaignsAvailabilityDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignDurationTitle')} description={t('campaignDurationDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignProductsTitle')} description={t('campaignProductsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignPurchaseOptionsTitle')} description={t('campaignPurchaseOptionsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={t('campaignFulfillmentTitle')} description={t('campaignFulfillmentDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
