import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form'

import { Banner, Layout } from '@shopify/polaris'

import { zodResolver } from '@hookform/resolvers/zod'

import { FetchLocalizationResponse, MutateLocalizationPayload, DateLocales } from '@/common/types'

import { DateSettings } from './DateSettings'
import { Labels } from './Labels'
import { z } from 'zod'
import { useMutateLocalization } from '@/hooks/useMutateLocalization'
import merge from 'ts-deepmerge'
import { useTranslation } from 'react-i18next'
import { SaveBar } from '@shopify/app-bridge-react'
import { Disabled } from '../shared/Disabled'
import useScrollToError from '@/hooks/useScrollToError'
import { localisationQueryOptions } from '@/common/queryOptions'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { openBeacon } from '@/common/functions'
import { useState } from 'react'

const schema = z.object({
  dateFormat: z.string().optional(),
  dateLocale: z.enum(DateLocales),
  purchaseOptionLabel: z.string().trim().nullable(),
  preorderLabel: z.string().trim().nullable(),
  backorderLabel: z.string().trim().nullable(),
  expectedShipDatePrefix: z.string().trim().nullable(),
  quickAddButtonCta: z.string().trim().nullable(),
  discountPrefix: z.string().trim().nullable(),
  asapLabel: z.string().trim().nullable(),
  unknownLabel: z.string().trim().nullable(),
})

export type FormSchema = z.infer<typeof schema>

const defaultValues = {
  dateFormat: 'D MMMM YYYY',
  dateLocale: 'en',
  purchaseOptionLabel: null,
  preorderLabel: null,
  backorderLabel: null,
  expectedShipDatePrefix: null,
  quickAddButtonCta: null,
  discountPrefix: null,
  asapLabel: null,
  unknownLabel: null,
} satisfies FormSchema

const transformDataToForm = (data: FetchLocalizationResponse): FormSchema => {
  return merge.withOptions({ allowUndefinedOverrides: false }, defaultValues, {
    dateFormat: data.dateFormat,
    dateLocale: data.dateLocale,
    purchaseOptionLabel: data.purchaseOptionLabel,
    preorderLabel: data.preorderLabel,
    backorderLabel: data.backorderLabel,
    expectedShipDatePrefix: data.expectedShipDatePrefix,
    quickAddButtonCta: data.quickAddButtonCta,
    discountPrefix: data.discountPrefix,
    asapLabel: data.asapLabel,
    unknownLabel: data.unknownLabel,
  })
}

const LocalizationForm = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [errorMessage, setErrorMessage] = useState<String | null>(null)

  const { data: settings } = useSuspenseQuery(localisationQueryOptions())

  const defaultFormValues = settings ? transformDataToForm(settings) : defaultValues

  const form = useForm<MutateLocalizationPayload>({
    defaultValues: defaultFormValues,
    resolver: zodResolver(schema),
    mode: 'onChange',
  })

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const mutateLocalization = useMutateLocalization()

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onError: SubmitErrorHandler<MutateLocalizationPayload> = () => {
    setCanFocus(true)
  }

  // Create our actions
  const onSubmit: SubmitHandler<MutateLocalizationPayload> = async (formValues) => {
    setErrorMessage(null)

    try {
      await mutateLocalization.mutateAsync(formValues, {
        onSuccess: (data) => {
          reset(schema.parse(data))

          queryClient.invalidateQueries({
            queryKey: localisationQueryOptions().queryKey,
          })

          shopify.toast.show(t('localisationUpdatedToast'), {
            duration: 2000,
          })
          return data
        },
      })
    } catch (error) {
      setErrorMessage(t('localisationSubmissionError'))
    }
  }

  return (
    <FormProvider {...form}>
      <SaveBar id="save-bar" open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onSubmit, onError)()}
          disabled={mutateLocalization.isPending}
          loading={mutateLocalization.isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={mutateLocalization.isPending}></button>
      </SaveBar>
      {errorMessage && (
        <Layout.Section>
          <Banner
            tone="critical"
            title={t('localisationSubmissionErrorTitle')}
            action={{
              content: t('genericContactSupport'),
              onAction: async () => {
                openBeacon('ASK')
              },
            }}
            onDismiss={() => {
              setErrorMessage(null)
            }}
          >
            {errorMessage}
          </Banner>
        </Layout.Section>
      )}
      <Layout.AnnotatedSection title={t('localisationDateTitle')} description={t('localisationDateDescription')}>
        <Disabled isDisabled={mutateLocalization.isPending}>
          <DateSettings />
        </Disabled>
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection title={t('localisationLabelsTitle')} description={t('localisationLabelsDescription')}>
        <Disabled isDisabled={mutateLocalization.isPending}>
          <Labels />
        </Disabled>
      </Layout.AnnotatedSection>
    </FormProvider>
  )
}

export { LocalizationForm }
