import { Badge, BadgeProgressValue } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

const BackorderStatusBadge = ({ backorderEnabled = false, status = 'COMPLETE', withProgress = true }) => {
  const { t } = useTranslation()

  let progress: BadgeProgressValue
  let label: string
  let tone: 'info' | 'success' | 'critical' | undefined

  if (status === 'COMPLETE') {
    if (backorderEnabled) {
      progress = BadgeProgressValue.Complete
      label = t('backorderStatusActive')
      tone = 'success'
    } else {
      progress = BadgeProgressValue.PartiallyComplete
      label = t('backorderStatusInactive')
      tone = undefined
    }
  } else if (status === 'ERROR') {
    progress = BadgeProgressValue.PartiallyComplete
    label = t('backorderStatusError')
    tone = 'critical'
  } else {
    progress = BadgeProgressValue.PartiallyComplete
    label = t('backorderStatusUpdating')
    tone = 'info'
  }

  return label ? (
    <Badge progress={withProgress ? progress : undefined} tone={tone}>
      {label}
    </Badge>
  ) : null
}

export { BackorderStatusBadge }
