import { buildAPIUrl } from '@/common/functions'
import { FetchSettingsResponse, MutateBISPayload } from '@/common/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export const useMutateBisSettings = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const requestURL = buildAPIUrl('settings/bis')

  return useMutation<FetchSettingsResponse, unknown, Partial<MutateBISPayload>>({
    mutationFn: (data: Partial<MutateBISPayload> = {}) =>
      fetch(requestURL, {
        body: JSON.stringify(data),
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            shopify.toast.show(t('backInStockSettingsUpdatedToast'), {
              duration: 2000,
            })
            return res.json()
          }
        })
        .then((res) => res.message),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: ['bisSetting'],
      })
    },
  })
}
