// @ts-nocheck

import { Box, Checkbox, Grid, InlineStack, Text } from '@shopify/polaris'
import { PlanCard } from './PlanCard'
import { useMutatePlan } from '@/hooks/useMutatePlan'
import { getBasePlanName } from '@/common/functions'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSuspenseQueries } from '@tanstack/react-query'
import { appPlanQueryOptions, reportsUsageQueryOptions } from '@/common/queryOptions'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { CustomPlanCard } from './CustomPlanCard'

const PlanSelector = () => {
  const { t } = useTranslation()

  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null)

  const [
    { data: usageData },
    {
      data: { message: plans },
    },
  ] = useSuspenseQueries({
    queries: [reportsUsageQueryOptions(), appPlanQueryOptions()],
  })

  const mutation = useMutatePlan()
  const currentPlanBaseName = getBasePlanName(usageData.plan.name)
  const currentPlanName = getBasePlanName(usageData.plan.name, false)

  const [showAnnual, setShowAnnual] = useState<boolean>(currentPlanName.indexOf('(Annual)') > -1)

  const appPlans = {
    freePlan: {
      index: 0,
      key: 'Free',
      selected: 'Free' === currentPlanBaseName,
      monthly: plans.find((plan) => plan.key === 'FREE'),
      annual: plans.find((plan) => plan.key === 'FREE'),
    },
    starterPlan: {
      index: 1,
      key: 'Launch',
      selected: 'Launch' === currentPlanBaseName,
      monthly: plans.find((plan) => plan.key === 'LAUNCH' && plan.interval === 'EVERY_30_DAYS'),
      annual: plans.find((plan) => plan.key === 'LAUNCH' && plan.interval === 'ANNUAL'),
    },
    businessPlan: {
      index: 2,
      key: 'Grow',
      selected: 'Grow' === currentPlanBaseName,
      monthly: plans.find((plan) => plan.key === 'GROW' && plan.interval === 'EVERY_30_DAYS'),
      annual: plans.find((plan) => plan.key === 'GROW' && plan.interval === 'ANNUAL'),
    },
    plusPlan: {
      index: 3,
      key: 'Scale',
      selected: 'Scale' === currentPlanBaseName,
      monthly: plans.find((plan) => plan.key === 'SCALE' && plan.interval === 'EVERY_30_DAYS'),
      annual: plans.find((plan) => plan.key === 'SCALE' && plan.interval === 'ANNUAL'),
    },
    enterprisePlan: {
      index: 4,
      key: 'Partner',
      selected: 'Partner' === currentPlanBaseName,
      monthly: plans.find((plan) => plan.key === 'PARTNER' && plan.interval === 'EVERY_30_DAYS'),
      annual: plans.find((plan) => plan.key === 'PARTNER' && plan.interval === 'ANNUAL'),
    },
  }

  const isCustomPlan = usageData.plan.isCustom

  const onClickPlanUpgrade = (appId: string | null) => {
    let isUpgrading = true

    const currentPlanKey = Object.keys(appPlans).find((k) => appPlans[k].selected)
    const selectedPlanKey = Object.keys(appPlans).find((k) => appPlans[k].monthly.uuid === appId || appPlans[k].annual.uuid === appId)
    const currentPlan = appPlans[currentPlanKey]
    const selectedPlan = appPlans[selectedPlanKey]

    if (!currentPlanKey || !selectedPlan) {
      isUpgrading = true
    } else {
      isUpgrading = currentPlan.index <= selectedPlan.index
    }

    if (isUpgrading && !isCustomPlan) {
      upgradePlan(appId)
    } else {
      setSelectedPlanId(appId)
      const downgradeModal = document.getElementById('downgrade-modal') as UIModalElement
      downgradeModal.show()
    }
  }

  const upgradePlan = async (appId: string | null) => {
    const res = await mutation.mutateAsync({
      appId,
    })

    if (res) {
      open(res, '_top')
    }
  }

  const onConfirmPlanDowngrade = () => {
    upgradePlan(selectedPlanId)
    const downgradeModal = document.getElementById('downgrade-modal') as UIModalElement
    downgradeModal.hide()
  }

  return (
    <>
      <Box paddingBlockEnd="200">
        <InlineStack align="space-between">
          <SectionTitle title={t('appPlansAvailableTitle')} description={t('appPlansAvailableSubtitle')} />
          <Box paddingInline={{ xs: '400', sm: '0', md: '0', lg: '0', xl: '0' }}>
            <Checkbox label={t('appPlansShowAnnualPricingLabel')} checked={showAnnual} onChange={setShowAnnual} />
          </Box>
        </InlineStack>
      </Box>
      <Grid columns={{ xs: 6, sm: 6, md: 12, lg: 12 }}>
        {Object.keys(appPlans).map((k) => {
          const plan = showAnnual ? appPlans[k].annual : appPlans[k].monthly
          if (!plan) {
            return null
          }
          return (
            <Grid.Cell key={plan.uuid} columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
              <PlanCard
                plan={plan}
                isCurrent={appPlans[k].selected}
                frequency={showAnnual ? t('appPlansFrequencyYear') : t('appPlansFrequencyMonth')}
                button={{
                  content: t('appPlansChangeCta'),
                  props: {
                    variant: 'primary',
                    onClick: () => onClickPlanUpgrade(plan.uuid),
                    disabled: mutation.isPending,
                    loading: mutation.isPending && mutation.variables.appId === plan.uuid,
                  },
                }}
              />
            </Grid.Cell>
          )
        })}
        <Grid.Cell key="custom-plan" columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 4 }}>
          <CustomPlanCard customPlan={usageData.plan.isCustom ? usageData.plan : null} isCurrent={isCustomPlan} />
        </Grid.Cell>
      </Grid>

      <ui-modal id="downgrade-modal">
        <Box padding="400">
          <Text as="p">{t('appPlansDowngradeDescription')}</Text>
        </Box>
        <ui-title-bar title={t('appPlansDowngradeTitle')}>
          <button variant="primary" tone="critical" onClick={onConfirmPlanDowngrade}>
            {t('appPlansDowngradeCta')}
          </button>
          <button
            onClick={() => {
              const downgradeModal = document.getElementById('downgrade-modal') as UIModalElement
              downgradeModal.hide()
            }}
          >
            {t('appPlansDowngradeCancelCta')}
          </button>
        </ui-title-bar>
      </ui-modal>
    </>
  )
}

export { PlanSelector }
