import React from 'react'
import { formatPrice } from '@/common/functions'
import { reportsUnitsSoldQueryOptions } from '@/common/queryOptions'
import { DataTable, Card, Box, BlockStack, EmptyState, Text } from '@shopify/polaris'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from '@tanstack/react-router'
import { CardHeader } from '../shared/CardHeader'
import { useTranslation } from 'react-i18next'
import ordersEmptyState from '@/illustrations/orders-empty-state.svg'

interface ProcessedData {
  [currency: string]: {
    products: ProductData[]
    totalUnitsSold: number
    totalRevenue: number
  }
}

interface ProductData {
  product: string
  unitsSold: number
  revenue: number
  variants: VariantData[]
}

interface VariantData {
  variant: string
  unitsSold: number
  revenue: number
}

function CampaignUnitsSold() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { uuid } = useParams({ strict: false }) as { uuid: string }

  const {
    data: { data },
  } = useSuspenseQuery(reportsUnitsSoldQueryOptions({ uuid }))

  if (data.length === 0) {
    return (
      <Card padding="0">
        <EmptyState
          heading={t('reportCampaignUnitsSoldBannerNoSales')}
          image={ordersEmptyState}
          action={{
            content: t('reportCampaignUnitsSoldBackCta'),
            onAction: async () => {
              /* @ts-ignore */
              await shopify.saveBar.leaveConfirmation()

              navigate({ to: `/preorders/$uuid`, params: { uuid } })
            },
          }}
        >
          <Text as="p">{t('reportCampaignUnitsSoldBannerNoSalesDescription')}</Text>
        </EmptyState>
      </Card>
    )
  }

  const processedData = processData(data)

  return (
    <BlockStack gap="400">
      {Object.entries(processedData).map(([currency, data]) => (
        <BlockStack key={currency} gap="200">
          <CardHeader
            title={t('salesBreakdownTitle', {
              currency,
            })}
          />
          <Card padding="0">
            <DataTable
              columnContentTypes={['text', 'numeric', 'numeric']}
              headings={[t('salesBreakdownProduct'), t('salesBreakdownUnitsSold'), t('salesBreakdownRevenue')]}
              rows={generateRows(data.products, currency)}
              totals={['', data.totalUnitsSold, `${formatPrice(data.totalRevenue, currency)} ${currency}`]}
              showTotalsInFooter
              totalsName={{
                singular: t('genericTotal'),
                plural: t('genericTotals'),
              }}
            />
          </Card>
        </BlockStack>
      ))}
    </BlockStack>
  )
}

const processData = (
  data: {
    shopId: string
    productId: string
    variantId: string
    productName: string
    variantName: string | null
    currency: string
    total: number
    qty: number
    status: string
  }[]
): ProcessedData => {
  // Initialize an object to store data for each currency
  const processedData: ProcessedData = {}

  data.forEach((item) => {
    const { productName, variantName, total, qty, currency } = item

    // Initialize currency data if it doesn't exist
    if (!processedData[currency]) {
      processedData[currency] = { products: [], totalUnitsSold: 0, totalRevenue: 0 }
    }

    const currencyData = processedData[currency]

    // Find or create product data
    let product = currencyData.products.find((p) => p.product === productName)
    if (!product) {
      product = { product: productName, unitsSold: 0, revenue: 0, variants: [] }
      currencyData.products.push(product)
    }

    // Update product data
    product.unitsSold += qty
    product.revenue += total

    // Find or create variant data if applicable
    if (variantName) {
      let variant = product.variants.find((v) => v.variant === variantName)
      if (!variant) {
        variant = { variant: variantName, unitsSold: 0, revenue: 0 }
        product.variants.push(variant)
      }
      variant.unitsSold += qty
      variant.revenue += total
    }

    // Update currency totals
    currencyData.totalUnitsSold += qty
    currencyData.totalRevenue += total
  })

  return processedData
}

// Helper function to generate table rows for products and variants
const generateRows = (products: ProductData[], currency: string): React.ReactNode[][] => {
  return products.flatMap((product) => {
    const productRow = ProductTableRow({
      product: product.product,
      unitsSold: product.unitsSold,
      revenue: product.revenue,
      currency,
    })

    const variantRows = product.variants.map((variant) =>
      VariantTableRow({
        variant: variant.variant,
        unitsSold: variant.unitsSold,
        revenue: variant.revenue,
        currency,
      })
    )

    return [productRow, ...variantRows]
  })
}

// Component to render a product row
const ProductTableRow = ({
  product,
  unitsSold,
  revenue,
  currency,
}: {
  product: string
  unitsSold: number
  revenue: number
  currency: string
}): React.ReactNode[] => {
  return [
    <strong key="product">{product}</strong>,
    <strong key="unitsSold">{unitsSold}</strong>,
    <strong key="revenue">{formatPrice(revenue, currency)}</strong>,
  ]
}

// Component to render a variant row
const VariantTableRow = ({
  variant,
  unitsSold,
  revenue,
  currency,
}: {
  variant: string
  unitsSold: number
  revenue: number
  currency: string
}): React.ReactNode[] => {
  return [
    <Box key="variant" paddingInlineStart="400" width="100%">
      {variant}
    </Box>,
    unitsSold,
    formatPrice(revenue, currency),
  ]
}

export { CampaignUnitsSold }
