import { buildAPIUrl } from '@/common/functions'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormSchema } from '@/components/preorders/CampaignForm/schema'

export const useMutateCampaign = (uuid?: string) => {
  const queryClient = useQueryClient()
  const searchParams = new URLSearchParams()
  if (uuid) searchParams.set('id', uuid)

  const requestURL = buildAPIUrl('campaign', searchParams)

  return useMutation({
    // TODO: I would have thought this didn't need to be partial
    mutationFn: (data: Partial<FormSchema> = {}) => {
      const payload = uuid ? { ...data, uuid } : data

      return fetch(requestURL, {
        body: JSON.stringify(payload),
        method: uuid ? 'PUT' : 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          } else {
            throw 'Error updating campaign'
          }
        })
        .then((res) => res.message)
    },
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: ['campaign'],
      })
    },
  })
}
