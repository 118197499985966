import { buildAPIUrl } from '@/common/functions'
import { bisListContactQueryOptions } from '@/common/queryOptions'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

export interface BisRemoveContactQueryParams {
  id: string
}

export const useMutateRemoveContact = () => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation<any, unknown, BisRemoveContactQueryParams>({
    mutationFn: (data: BisRemoveContactQueryParams) => {
      const searchParams = new URLSearchParams()
      searchParams.set('id', data.id)

      return fetch(buildAPIUrl('bisList', searchParams), {
        body: JSON.stringify(data),
        method: 'DELETE',
      })
        .then((res) => {
          if (res.status === 200) {
            shopify.toast.show(t('backInStockContactTableActionRemoveContactToast'), {
              duration: 2000,
            })
            return res.json()
          }
        })
        .then((res) => res.message)
    },
    onSettled(data) {
      queryClient.invalidateQueries({
        queryKey: bisListContactQueryOptions({
          id: data.id,
        }).queryKey,
      })
    },
  })
}
