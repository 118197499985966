import { useMutateCampaignSchedule } from '@/hooks/useMutateCampaignSchedule'
import { Modal, TitleBar, useAppBridge } from '@shopify/app-bridge-react'
import { BlockStack, Box, Checkbox, Text } from '@shopify/polaris'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const CampaignResumeModal = ({ uuid, stockLimit }: { uuid: string; stockLimit: number | null }) => {
  const { t } = useTranslation()
  const shopify = useAppBridge()
  const [resetStock, setResetStock] = useState(false)

  const campaignScheduleMutation = useMutateCampaignSchedule(uuid)

  const handleConfirmResume = () => {
    shopify.modal.hide('resume-campaign-modal')
    campaignScheduleMutation.mutate({ action: 'PUBLISH', setStock: resetStock })
  }

  return (
    <Modal id="resume-campaign-modal">
      <Box padding="400">
        <BlockStack gap="400">
          <Text as="p">
            {t('campaignsManageResumeModalDescription', {
              stock: stockLimit,
            })}
          </Text>
          <Checkbox
            label={t('campaignsManageResumeModalResetStockLabel', {
              stock: stockLimit,
            })}
            checked={resetStock}
            onChange={() => setResetStock(!resetStock)}
          />
        </BlockStack>
      </Box>

      <TitleBar title={t('campaignsManageResumeModalTitle')}>
        <button variant="primary" onClick={handleConfirmResume}>
          {resetStock
            ? t('campaignsManageResumeModalResetStockCta', {
                stock: stockLimit,
              })
            : t('campaignsManageResumeModalLeaveStockCta')}
        </button>
        <button
          onClick={() => {
            shopify.modal.hide('resume-campaign-modal')
          }}
        >
          {t('genericCancel')}
        </button>
      </TitleBar>
    </Modal>
  )
}

export { CampaignResumeModal }
